import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
import vodacomLogo from '../../../mobile/img/VodacomMusic.svg'
import redTick from '../../../mobile/img/red-tick.svg'

SwiperCore.use([Navigation, Pagination, Autoplay]);

export default function SwiperSlider({ language }) {
  return (
    <div className="swiper-container swiper1">
      <div className="swiper-wrapper" >
        <Swiper
        autoplay={false} // Turn off autoplay
        allowTouchMove={false} // Disable dragging/swiping
        navigation={false} // Disable navigation arrows
        
          className={"swiper1"}
        >
          {/* code for static banner */}
          <SwiperSlide>
            <div className="swiper-slide">
              <div className="slidiv">
                <div className="sliderrhtblur"></div>
                <div className="sliderbtmblur"></div>
                {/* <Image src={"sliderimg.png"} alt="sliderimg" />  */}
                <img
                  src={`https://images.hungama.com/c/uploads_hungama/20240919/20240919100134_image_mymuze.png`}
                  alt="sliderimg"
                />
                <div className="posslidcon posslidconSecondary">
                <div>
                  <img className="vodacomLogoDesktop"
                  src={vodacomLogo}      
                />
                  </div>
                  {/* <h1 className="slidftxt">{language?.pay_banner_title_1}</h1> */}
                  <h1 className="slidftxt">{language?.go_premium}</h1>

                  {/* <h2 className="slidstxt">
                    {language?.pay_banner_subtitle_1}
                  </h2> */}
                  {/* <div className="goldicnslid"></div> */}
                  <div class="features-grid-desktop">
        <div class="feature">
            <span class="checkmark-desktop"><img src={redTick}/>  </span>
            <p>{language?.plan_feature_1}</p>
        </div>
        <div class="feature">
            <span class="checkmark-desktop"><img src={redTick}/>  </span>
            <p>{language?.plan_feature_2}</p>
        </div>
        <div class="feature">
            <span class="checkmark-desktop"><img src={redTick}/>  </span>
            <p>{language?.plan_feature_3}</p>
        </div>
        <div class="feature">
            <span class="checkmark-desktop"><img src={redTick}/>  </span>
            <p>{language?.plan_feature_4}</p>
        </div>
        <div class="feature">
            <span class="checkmark-desktop"><img src={redTick}/>  </span>
            <p>{language?.plan_feature_5}</p>
        </div>
        <div class="feature">
            <span class="checkmark-desktop"><img src={redTick}/>  </span>
            <p>{language?.plan_feature_6}</p>
        </div>
    </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="swiper-slide">
              <div className="slidiv">
                <div className="sliderrhtblur"></div>
                <div className="sliderbtmblur"></div>
                {/* <Image src={"sliderimg.png"} alt="sliderimg" />  */}
                <img
                  src={
                    `https://images.hungama.com/c/uploads_hungama/20240919/20240919100134_image_mymuze.png`
                  }
                  alt="sliderimg"
                />
                <div className="posslidcon">
                  <div className="noaddslid"></div>
                  <h1 className="slidftxt">{language?.pay_banner_title_2}</h1>
                  <h2 className="slidstxt">
                    {language?.pay_banner_subtitle_2}
                  </h2>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
}
