/* eslint-disable*/
import React, { useEffect, useState } from "react";
// import ReactGA from "react-ga";

import axios from "axios";
import { useLocation, useHistory } from "react-router-dom";

import get from "lodash.get";
import { ampRabbitMQBtnEvent, ampRabbitMQPageEvent } from "../../../utils";
import { language, checkUserRecentOrder } from "../../../utils/services";
import { plengTestUserIds } from "../../../../constants";

export const LinkAccount = (props) => {
  /* Hooks */
  const history = useHistory();
  const pathname = useLocation().pathname;
  const location = useLocation().search;
  const hostname = window.location.host;

  /* Params */
  const couponcode = new URLSearchParams(location).get("couponcode")
    ? new URLSearchParams(location).get("couponcode")
    : "";
  const plan_id = new URLSearchParams(location).get("plan_id");
  const product_id = new URLSearchParams(location).get("product_id");
  const platform_id = new URLSearchParams(location).get("platform_id");
  const country = new URLSearchParams(location).get("country");
  const plan_type = new URLSearchParams(location).get("plan_type");
  const identity = new URLSearchParams(location).get("identity");
  const content_id = new URLSearchParams(location).get("content_id");
  const uL = new URLSearchParams(location).get("upilist");
  const lan = new URLSearchParams(location).get("lang");
  const npay_redirect = new URLSearchParams(location).get("npay_redirect");
  const npay_campaignsource = new URLSearchParams(location).get(
    "npay_campaignsource"
  );
  const campaignsource = new URLSearchParams(location).get("campaignsource");
  const npay_affilaite = new URLSearchParams(location).get("npay_affilaite");
  const aff_id = new URLSearchParams(location).get("aff_id");
  const utm_source = new URLSearchParams(location).get("utm_source");
  const originalSource = new URLSearchParams(location).get("source");
  const urlparams = location.replace("?", "");
  const is_smart_pay_number = new URLSearchParams(location).get(
    "is_smart_pay_number"
  );
  const isPlengLiveEvent = new URLSearchParams(location).get(
    "isPlengLiveEvent"
  );
  const [linkingAccount, setLinkingAccount] = useState([]);

  /* States */
  const [plan, setPlan] = useState({ discountPrice: " " });
  const [featured, setFeatured] = useState({});
  const [payments, setPayments] = useState([]);
  const [wallets, setWallets] = useState([]);
  const [total_user_coins, setTotal_user_coins] = useState([]);
  const [netBankingPayments, setNetBankingPayments] = useState([]);
  const [upiPayments, setUpiPayments] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [isLoadingVerify, setisLoadingVerify] = useState(false);
  const [textData, setTextData] = useState({});
  const [pushbar, setPushbar] = useState({
    openCoupon: false,
    Verifybottom1: false,
    Verifybottom2: false,
    verifyRedeemCoins: false,
    verifyAmazonPay: false,
    isTimer: false,
    invalidSmartNumber: false,
    abaPopup: false,
    abalinkPopup:false
  });
  const [formData, setFormData] = useState({
    couponcode: couponcode,
    couponVerified: false,
    couponError: false,
    cardHolderName: "",
    cardNumber: "",
    expiryDate: "",
    cvv: "",
    upiid: "",
  });
  const [isCouponValid, setIsCouponValid] = useState(false);
  const [abaCardIframeLink, setAbaCardIframeLink] = useState("");
  const [abaCardPopUpParams, setAbaCardPopUpParams] = useState({});
  // const [linkingAccount, setLinkingAccount] = useState([]);
  // const [tabHideShow,setTabHideShow]=useState({
  //     upiTab:false,
  //     ccTab:false,
  // })

  /* Functions */

  const goToPreviousPath = (contentId, planName) => {
    if (
      platform_id === "1" &&
      plan_type?.substring(0, 2).toLowerCase() === "le"
    ) {
      // window.location.href = `${process.env.REACT_APP_HANGAMA_URL}/wvclose.php`;
      window.location.href = `https://hungama.com/payment/success/`;
    }
    history.goBack();
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    let data = { ...formData };
    data[name] = value;
    setFormData(data);
  };

  const handleGet = async () => {
   const url = `${process.env.REACT_APP_API_URL}/v1/user/check_linking_account/getLinkingAccount?identity=${identity}`;
  
    try {
      const response = await axios.get(url);
      console.log("Response Data:", response.data);

      if (response?.data && response?.data?.result?.length > 0) {
        setLinkingAccount(response.data.result);
        console.log("Linking account", response.data.result);
      }
    } catch (error) {
      console.error("Error fetching linking account:", error.response ? error.response.data : error.message);
    }
  };


  const handleDelete = async (linkAccountData) => {
   return axios({
      method: "GET",
     url: `${process.env.REACT_APP_API_URL}/v1/user/check_linking_account/delete?id=${linkAccountData.ID}&identity=${linkAccountData.IDENTITY}&ctid=${linkAccountData.CTID}&pwt=${linkAccountData.PWT}`,
     headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.statusCode == 200 && res.status == 'success') {
          console.log("enter here in SUCCESS");
          handleGet();
          return { status: "SUCCESS" };
        }else if (res.statusCode == 200 && res.status == 'Failed' && res.message == "No Data Found"){
          console.log("enter here in FAILURE");
          return { status: "FAILURE" };
        }
        else if (res.statusCode == 500 && res.status == 'Failed' && res.message == "External API failed, deletion aborted"){
          return { status: " EXTERNAL API FAILURE" };
        }
       
      })
      .catch((err) => {
        return { status: "FAILED" };
      });
  }


  const handleCoupenClick = (e) => {
    // amplitudeBtnEvent(identity, "Btn_Open_Coupon", product_id, platform_id);
    ampRabbitMQBtnEvent(
      {
        identity: identity,
        product_id: product_id,
        platform_id: platform_id,
      },
      "Btn_Open_Coupon"
    );
    const name = e.target.getAttribute("data-name");
    let value = e.target.getAttribute("value");
    setPushbar((prev) => {
      // console.log(value);
      return { ...prev, [name]: value === "true" ? true : false }; //value === "true" ? true : false
    });
  };

  const handleClearCoupon = () => {
    // setFormData((prev) => { return { ...prev, couponcode: "" } });
    setFormData((prev) => {
      return {
        ...prev,
        couponcode: "",
        couponError: false,
      };
    });
    // amplitudeBtnEvent(identity, "Btn_Remove_Coupon", product_id, platform_id);
    ampRabbitMQBtnEvent(
      {
        identity: identity,
        product_id: product_id,
        platform_id: platform_id,
      },
      "Btn_Remove_Coupon"
    );
  };

  const cancelVerifyCoupon = (e) => {
    if (e) e.preventDefault();
    setFormData((prev) => {
      return { ...prev, couponcode: "", couponVerified: false };
    });
    setPushbar((prev) => {
      return { ...prev, openCoupon: false };
    });
    setPlan((prev) => {
      return { ...prev, discountPrice: undefined };
    });
    setIsCouponValid(false);
    // amplitudeBtnEvent(identity, "Btn_Remove_Coupon", product_id, platform_id);
    ampRabbitMQBtnEvent(
      { identity: identity, product_id: product_id, platform_id: platform_id },
      "Btn_Remove_Coupon"
    );
  };

  const handleCardPayments = (payment_id, plan_details_id) => {
    let url;
    if (payment_id == 22) {
      url = `${
        process.env.REACT_APP_HANGAMA_URL
      }/billing/smartpay/SmartpayBilling.php?payment_id=${payment_id}&identity=${identity}&plan_details_id=${plan_details_id}&product_id=${product_id}&type=charge&${urlparams}&lang=${lan}&coupon_code=${
        formData?.couponcode ? formData?.couponcode : ""
      }`;
    } else {
      url = `${
        process.env.REACT_APP_HANGAMA_URL
      }/billing/pay.php?payment_id=${payment_id}&identity=${identity}&plan_details_id=${plan_details_id}&product_id=${product_id}&type=charge&${urlparams}&lang=${lan}&coupon_code=${
        formData?.couponcode ? formData?.couponcode : ""
      }`;
    }
    console.log(
      "payment url::",
      url,
      "payment id",
      payment_id,
      "plan details",
      plan_details_id
    );
    window.location.href = url;
  };

  const verifyCoupon = (e) => {
    // amplitudeBtnEvent(identity, "Btn_Submit_Coupon", product_id, platform_id);
    ampRabbitMQBtnEvent(
      { identity: identity, product_id: product_id, platform_id: platform_id },
      "Btn_Submit_Coupon"
    );
    if (e) e.preventDefault();
    const url = `${process.env.REACT_APP_HANGAMA_URL}/coupons/get_discounted_value.php?coupon_code=${formData.couponcode}&${urlparams}`;
    axios
      .get(url)
      .then((res) => {
        setisLoadingVerify(false);
        const couponDiscount = get(res, "data.coupon_details.value", "");
        const coupontype = get(res, "data.coupon_details.type", "");
        const couponStatus = get(res, "data.status", "");

        if (couponStatus === 1) {
          setIsCouponValid(true);
        }

        if (couponDiscount !== "" || null || undefined) {
          let discount;
          if (coupontype === "discounted") {
            discount =
              plan?.plan_price - (couponDiscount / 100) * plan?.plan_price;
          } else if (coupontype === "flat") {
            discount = plan?.plan_price - couponDiscount;
          }
          console.log(discount);

          setFormData((prev) => {
            return { ...prev, couponVerified: true, couponError: false };
          });
          setPushbar((prev) => {
            return { ...prev, openCoupon: false };
          });
          setPlan((prev) => {
            return { ...prev, discountPrice: discount };
          });
        } else {
          setFormData((prev) => {
            return { ...prev, couponVerified: false, couponError: true };
          });
          // setPushbar((prev) => {
          //     return { ...prev, openCoupon: false }
          // });
          setPlan((prev) => {
            return { ...prev, discountPrice: undefined };
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /* checkRecentOrder Function and Redirect to Payment Success/Fail according to status */
  const checkRecentOrder = async () => {
    //params from abapopup
    if (Object.keys(abaCardPopUpParams).length !== 0) {
      // let { identity, product_id, plan_details_id, payment_id } =
      //   abaCardPopUpParams;
      //check recent order in delay and redirect as per status

      let { status, data } = await checkUserRecentOrder(abaCardPopUpParams);
      if (status === "success" && data?.order_data?.order_status == 1) {
        window.location.href = `/pleng/payment_success?identity=${data?.order_data?.identity}&order_id=${data?.order_data?.order_id}&lang=${lan}&product_id=${data?.order_data?.product_id}&platform_id=${data?.order_data?.platform_id}`;
      } else if (status === "success" && data?.order_data?.order_status == 5) {
        window.location.href = `/pleng/payment_fail?identity=${data?.order_data?.identity}&order_id=${data?.order_data?.order_id}&lang=${lan}&product_id=${data?.order_data?.product_id}&platform_id=${data?.order_data?.platform_id}`;
      } else {
        checkRecentOrder();
      }
    }
  };

  // console.log(couponcode);
  if (isLoadingVerify) {
    if (couponcode !== "") {
      verifyCoupon();
    }
  }

  function maskCreditCard(cardNumber) {
    const parts = cardNumber.split('-');
    for (let i = 0; i < parts.length - 1; i++) {
      parts[i] = parts[i].replace(/\d/g, '*');
    }
    return parts.join(' ');
  }

  useEffect(() => {
    if (isLoading) {
      language(lan, product_id).then((res) => {
        // setDefaultTextData(res?.default);
        setTextData(res?.secondLang ? res["secondLang"] : res["default"]);
      });



     handleGet();
    
      // const url = `${process.env.REACT_APP_API_URL}/v1/user/check_linking_account/getLinkingAccount`;
      // const params = {
      //   identity: 'User1232',
      // };
      
      // axios
      //   .get(url, { params })
      //   .then((response) => {
      //     console.log('Response Data:', response.data);
      //     if(response?.data && response?.data?.result?.length>0){
      //       setLinkingAccount(response?.data?.result);
      //       console.log('Linking account', linkingAccount);
      //     }
      //   })
      //   .catch((error) => {
      //     console.error('Error fetching linking account:', error.response ? error.response.data : error.message);
      //   });

      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        "api-key": process.env.REACT_APP_API_KEY,
      };

      const body = {
        product_id: product_id,
        platform_id: platform_id,
        country: country,
        plan_id: plan_id,
        plan_type: plan_type,
        content_id: content_id,
        identity: identity,
      };

      axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/v1/billing/payments`,
        headers: headers,
        data: body,
      })
        .then((res) => {
          const featuredData = res?.data?.featuredPayment;
          const planData = res?.data?.planInfo;
          const paymentsdata = res?.data?.payments;
          const walletPaymentsdata = res?.data?.walletPayments;
          const upiPaymentsdata = res?.data?.upiPayments;
          const netBankingPaymentsdata = res?.data?.netBankingPayments;
          // Assign value to a key
          sessionStorage.setItem(
            "plan_name_val",
            planData.plan_name + "_" + planData.plan_price
          );
          setTotal_user_coins(res?.data?.total_user_coins);
          setFeatured(featuredData);
          setPlan(planData);
          setPayments(paymentsdata);
          setWallets(walletPaymentsdata);
          setNetBankingPayments(netBankingPaymentsdata);
          setUpiPayments(upiPaymentsdata);
          setLoading(false);
          setisLoadingVerify(true);

          /* amplitude code start*/
          let pageType = "PG Page_Subscription";

          ampRabbitMQPageEvent({
            identity: identity,
            "Page Type": pageType,
            Source: "Plan Page",
            "Original Source": originalSource ? originalSource : "N/A",
            "Plan Name": planData?.plan_name,
            "Plan Validity": planData?.plan_valid,
            "Plan Value": planData?.plan_price?.toFixed(2),
            "Plan Currency": planData?.plan_currency,
            "Plan ID": plan_id,
            "Page Language": lan ? lan : "en",
            product_id: product_id,
            platform_id: platform_id,
          });
          /* amplitude code end*/

          //Handle anndroid Google Wallet Payment for Live Event
          if (
            platform_id === "1" &&
            isPlengLiveEvent === "1" &&
            paymentsdata.length != 0
          ) {
            let { payment_id, plan_details_id } = paymentsdata?.find(
              (el) => el?.payment_id === 11
            );
            // alert("handleAndroidGoogleWalletLiveEvent..!!");
            handleAndroidGoogleWalletLiveEvent(
              identity,
              plan_details_id,
              product_id,
              urlparams,
              couponcode,
              payment_id
            );
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
      const logData = {
        url: `${hostname}${pathname}`,
        params: location,
        couponcode: couponcode,
        plan_id: plan_id,
        product_id: product_id,
        platform_id: platform_id,
        country: country,
        identity: identity,
        content_id: content_id,
        plan_type: plan_type,
        upiList: uL,
      };

      const data = JSON.stringify(logData);
      const body1 = {
        logData: data,
      };

      axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/v1/frontendlogs`,
        headers: headers,
        data: body1,
      })
        .then((res) => {})
        .catch((err) => {
          console.log(err);
        });

      if (is_smart_pay_number && is_smart_pay_number == "0") {
        setPushbar((prev) => {
          return { ...prev, invalidSmartNumber: true };
        });
      }
    }
  }, [
    utm_source,
    originalSource,
    aff_id,
    npay_redirect,
    aff_id,
    campaignsource,
    npay_affilaite,
    npay_campaignsource,
    pushbar.openCoupon,
    country,
    couponcode,
    hostname,
    identity,
    location,
    pathname,
    uL,
    isLoading,
    plan_id,
    urlparams,
    content_id,
    plan_type,
    platform_id,
    product_id,
    lan,
    is_smart_pay_number,
    isPlengLiveEvent,
  ]);

  const time = new Date();
  time.setSeconds(time.getSeconds() + 300); // 10 minutes timer

  // console.log(upiPayments);
  useEffect(() => {
    if (
      pushbar.openCoupon ||
      pushbar.Verifybottom1 ||
      pushbar.Verifybottom2 ||
      pushbar.verifyRedeemCoins ||
      pushbar.verifyAmazonPay ||
      pushbar.invalidSmartNumber ||
      pushbar.abaPopup
    ) {
      document.body.style.overflow = "hidden";
      document.body.style.position = "fixed"; //ios
      document.body.style.height = "100vh"; //ios
      document.body.style.width = "100%"; //ios
    } else if (!pushbar.openCoupon) {
      document.body.style.overflow = "visible";
      document.body.style.removeProperty("position"); //ios
      document.body.style.removeProperty("height"); //ios
      document.body.style.removeProperty("width"); //ios
    }
  }, [pushbar]);

  const handleAndroidGoogleWalletLiveEvent = (
    p_identity,
    p_plan_details_id,
    p_product_id,
    p_urlparams,
    p_coupon_code,
    p_payment_id
  ) => {
    // amplitudeBtnEvent(identity, "Btn_GoogleWallet", product_id, platform_id);
    ampRabbitMQBtnEvent(
      { identity: identity, product_id: product_id, platform_id: platform_id },
      "Btn_GoogleWallet"
    );
    // gaEventTracker('google_wallet',p_plan_details_id) ;
    let url;
    url = `${process.env.REACT_APP_HANGAMA_URL}/billing/pay.php?payment_id=${p_payment_id}&identity=${p_identity}&plan_details_id=${p_plan_details_id}&product_id=${p_product_id}&type=charge&${p_urlparams}&coupon_code=${p_coupon_code}`;
    // console.log("payment url::", url);
    window.location.href = url;
  };

  return (
    <>
      {isLoading ? (
        <div className="loading">Loading...</div>
      ) : (
        <>
          <div
            className="wrapper"
            style={{
              pointerEvents:
                pushbar.openCoupon ||
                pushbar.Verifybottom1 ||
                pushbar.Verifybottom2 ||
                pushbar.verifyRedeemCoins ||
                pushbar.verifyAmazonPay ||
                pushbar.invalidSmartNumber ||
                pushbar.abaPopup
                  ? "none"
                  : "auto",
            }}
          >
            <div className="main-container">
              <div className="pay-head">
                {platform_id === "4" ? (
                  <div
                    onClick={() =>
                      goToPreviousPath(content_id, plan?.plan_name)
                    }
                    className="pay-back"
                  >
                    <img
                      src={
                        process.env.REACT_APP_ASSETS_URL +
                        "/mobile/pleng/images/back-arrow.svg"
                      }
                      alt="arrow"
                    />
                  </div>
                ) : (
                  ""
                )}
                {/* <span>Payment</span> */}
                {platform_id === "4" ? (
                  <span>{textData?.pleng_payment_heading}</span>
                ) : (
                  ""
                )}
              </div>
           
              {/* <div className="pay-optinbox">
                <div className="pay-optinbox-inner-linkAccount"> */}
                  {/* <h2 className="pay-title">
                    {textData?.pleng_payment_heading}
                  </h2> */}

                  {/* {payments
                    ?.sort(
                      (a, b) =>
                        parseFloat(a.display_order) -
                        parseFloat(b.display_order)
                    )
                    .map((data, i) =>
                      data?.payment_id === 20 ? (
                        <div
                          className="pay-mode-box"
                          key={i}
                          onClick={() => {
                            let abaCardurl = `${
                              process.env.REACT_APP_HANGAMA_URL
                            }/billing/pay.php?payment_id=${
                              data?.payment_id
                            }&identity=${identity}&plan_details_id=${
                              data?.plan_details_id
                            }&product_id=${product_id}&type=charge&${urlparams}&lang=${lan}&coupon_code=${
                              formData?.couponcode ? formData?.couponcode : ""
                            }`;
                            // console.log("abalink", abaCardurl);
                            //set ABA card iframe drawer link
                            setAbaCardIframeLink(abaCardurl);
                            setAbaCardPopUpParams({
                              identity: identity,
                              payment_id: data?.payment_id,
                              plan_details_id: data?.plan_details_id,
                              product_id: product_id,
                            });
                            setPushbar((prev) => {
                              return { ...prev, abaPopup: true };
                            });
                          }}
                        >
                          <div className="payment-box flex1">
                            <div className="pay-mode-icon-box">
                              <img
                                alt="paymode"
                                src={
                                  process.env.REACT_APP_ASSETS_URL +
                                  "/mobile/pleng/images/creditDebitIcon.svg"
                                }
                                className="pay-mode-img"
                              />
                            </div>
                            <div className="pay-moffer-box">
                              <span className="paym-name">
                                {textData?.pleng_payment_cc_dc}
                              </span>
                              <br />
                              <span className="payment-cc-dc-icon">
                                <img
                                  alt="cartTypesIcon"
                                  src={
                                    process.env.REACT_APP_ASSETS_URL +
                                    "/mobile/pleng/images/cardTypes.svg"
                                  }
                                  style={{ height: "13px" }}
                                />
                              </span>
                            </div>
                            <div className="pay-mnext-arrowbox">
                              <div className="pay-mnext-arrow">
                                <img
                                  alt="nextarrow"
                                  src={
                                    process.env.REACT_APP_ASSETS_URL +
                                    "/mobile/pleng/images/next-arrow.svg"
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                       : data?.payment_id === 22 ? (
                        <div
                          className="pay-mode-box"
                          key={i}
                          onClick={() =>
                            handleCardPayments(
                              data?.payment_id,
                              data?.plan_details_id
                            )
                          }
                        >
                          <div className="payment-box flex1">
                            <div className="pay-mode-icon-box">
                              <img
                                alt="smartpay"
                                src={
                                  process.env.REACT_APP_ASSETS_URL +
                                  "/mobile/pleng/images/smart-pay.png"
                                }
                                className="pay-mode-img smart-pay"
                              />
                            </div>
                            <div className="pay-moffer-box">
                              <span className="paym-name">
                                {textData?.pleng_payment_smart_pay}
                              </span>
                            </div>
                            <div className="pay-mnext-arrowbox">
                              <div className="pay-mnext-arrow">
                                <img
                                  alt="nextarrow"
                                  src={
                                    process.env.REACT_APP_ASSETS_URL +
                                    "/mobile/pleng/images/next-arrow.svg"
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                       : data?.payment_id === 23 ? (
                        <div
                          className="pay-mode-box"
                          key={i}
                          onClick={() =>
                            handleCardPayments(
                              data?.payment_id,
                              data?.plan_details_id
                            )
                          }
                        >
                          <div className="payment-box flex1">
                            <div className="pay-mode-icon-box">
                              <img
                                alt="paymodeimg"
                                src={
                                  process.env.REACT_APP_ASSETS_URL +
                                  "/mobile/pleng/images/ABA-pay.png"
                                }
                                className="pay-mode-img"
                              />
                            </div>
                            <div className="pay-moffer-box">
                              <span className="paym-name">
                                {textData?.pleng_payment_aba_pay}
                              </span>
                              <br />
                              <span className="aba-subtext">
                                Tap to pay with ABA mobile
                              </span>
                            </div>
                            <div className="pay-mnext-arrowbox">
                              <div className="pay-mnext-arrow">
                                <img
                                  alt="nextarrow"
                                  src={
                                    process.env.REACT_APP_ASSETS_URL +
                                    "/mobile/pleng/images/next-arrow.svg"
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : data?.payment_id === 25 &&
                        plengTestUserIds.includes(identity) ? (
                        <div
                          className="pay-mode-box"
                          key={i}
                          onClick={() =>
                            handleCardPayments(
                              data?.payment_id,
                              data?.plan_details_id
                            )
                          }
                        >
                          <div className="payment-box flex1">
                            <div className="pay-mode-icon-box">
                              <img
                                alt="acledapay"
                                src={
                                  process.env.REACT_APP_ASSETS_URL +
                                  "/mobile/pleng/images/acleda.logo.svg"
                                }
                                className="pay-mode-img smart-pay"
                              />
                            </div>
                            <div className="pay-moffer-box">
                              <span className="paym-name">
                                {textData?.pleng_payment_acleda
                                  ? textData?.pleng_payment_acleda
                                  : "Acleda Pay"}
                              </span>
                            </div>
                            <div className="pay-mnext-arrowbox">
                              <div className="pay-mnext-arrow">
                                <img
                                  alt="nextarrow"
                                  src={
                                    process.env.REACT_APP_ASSETS_URL +
                                    "/mobile/pleng/images/next-arrow.svg"
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )
                    )} */}

                       {linkingAccount
                    ?.map((linkAccountData, i) =>
                      <>
                        <div className="pay-optinbox">
                        <div className="pay-optinbox-inner-linkAccount">
                          <div className="pay-mode-box">
                          <div className="payment-box flex1">
                            <div className="pay-mode-icon-box">
                            <img
                                alt="paymode"
                                src={
                                  process.env.REACT_APP_ASSETS_URL +
                                  "/mobile/pleng/images/creditDebitIcon.svg"
                                }
                                className="pay-mode-img"
                              />
                            </div>
                            <div className="pay-moffer-box">
                              <span className="paym-name">
                                {maskCreditCard(linkAccountData?.MASK_ACCOUNT)}
                              </span>
                              <br />           
                            </div>
                            <div className="pay-mnext-arrowbox">
                              <div className="pay-mnext-arrow">
                              <img
                                      alt="nextarrow"
                                      src="https://images1.hungama.com/hmmi/delete%201.svg?updatedAt=1743076316942"
                                      onClick={() => handleDelete(linkAccountData)}
                                      style={{ cursor: "pointer" }}
                                    />
                              </div>
                            </div>
                          </div>
                          </div>
                        </div>
                        </div>
                      </>
                    )}
                {/* </div>
              </div> */}
            </div>
            <div 
  style={{
    position: "fixed",
    bottom: "10px",
    left: "50%",
    transform: "translateX(-50%)",
    width: "90%", // Adjust for responsiveness
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    zIndex: "1000",
  }}
>
  {/* First Button - Add New Card */}
  <button 
    style={{
      backgroundColor: "#333",
      color: "white",
      border: "none",
      borderRadius: "30px",
      padding: "12px",
      fontSize: "1rem",
      fontWeight: "bold",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: "8px",
      cursor: "pointer"
    }}
  >
    <span>💳</span> Add New Card
  </button>

  {/* Second Button - Add Other Payment Method */}
  <button 
    style={{
      background: "linear-gradient(to right, #00c853, #00e676)",
      color: "white",
      border: "none",
      borderRadius: "30px",
      padding: "12px",
      fontSize: "1rem",
      fontWeight: "bold",
      cursor: "pointer"
    }}
    onClick={(e) => {
      setPushbar((prev) => {
        return { ...prev, abalinkPopup: true };
      });
    }}
  >
    Add Other Payment Method
  </button>
</div>

          </div>
          <aside
            data-pushbar-id="bottom"
            className={`pushbar from_bottom ${
              pushbar?.openCoupon ? "opened" : ""
            }`}
          >
            <div className="paddcpbox">
              <div
                className="coupon-close"
                onClick={(e) => {
                  setPushbar((prev) => {
                    return { ...prev, openCoupon: !pushbar?.openCoupon };
                  });
                  // handleClearCoupon(e);
                }}
                data-pushbar-close
              >
                <img
                  alt="close"
                  src={
                    process.env.REACT_APP_ASSETS_URL +
                    "/mobile/pleng/images/close-btn.svg"
                  }
                />
              </div>
              <div className="close_sep"></div>
              <div className="cptitle">{textData?.pay_coupon_popup_text}</div>
              <div className="cpbxcon">
                <div className="cpbxcon-input-box">
                  <input
                    name="couponcode"
                    value={formData.couponcode}
                    onChange={(e) => handleInput(e)}
                    type="text"
                  />
                  {formData.couponError && formData?.couponcode !== "" && (
                    <img
                      alt="close icon"
                      onClick={(e) => handleClearCoupon(e)}
                      src={
                        process.env.REACT_APP_ASSETS_URL +
                        "/mobile/pleng/images/coupon_error_icon.svg"
                      }
                      className="error-closebtn"
                    />
                  )}
                </div>
                <div onClick={verifyCoupon} className="button-blue">
                  {textData?.pay_coupon_apply_text}
                </div>
              </div>
              {formData.couponError && (
                <div className="error-box">
                  {textData?.pay_coupon_error_text}
                </div>
              )}
            </div>
          </aside>
          {/* invalid smartPay number popup start */}
          <aside
            data-pushbar-id="bottom1"
            className={`pushbar from_bottom ht75 ${
              pushbar?.invalidSmartNumber ? "opened" : ""
            }`}
          >
            <div className="pop-up-wrapper">
              <div className="upi-popup-content pay-popup-declined">
                <div className="payment-declined">
                  <img
                    src={
                      process.env.REACT_APP_ASSETS_URL +
                      "/mobile/pleng/images/payment-declined-icon.svg"
                    }
                    className="smart pay payment declined icon"
                  />
                </div>
                <p className="p-txt1">Payment Declined</p>
                <p className="p-upiid r-points">
                  Smart number not detected. Please choose another payment
                  method.
                </p>
                <div className="p-paybtnbox">
                  <a
                    onClick={(e) => {
                      setPushbar((prev) => {
                        return {
                          ...prev,
                          invalidSmartNumber: !pushbar?.invalidSmartNumber,
                        };
                      });
                    }}
                    href="javascript:void(0)"
                    className="button-cancel mr-10"
                    data-pushbar-close
                  >
                    Close
                  </a>
                </div>
              </div>
            </div>
          </aside>
          {/* invalid smartPay number popup end */}

          {/* ABA PG popup start */}
          <aside
            data-pushbar-id="bottom2"
            className={`pushbar from_bottom ht100 ${
              pushbar?.abaPopup ? "opened" : ""
            }`}
          >
            <div className="pop-up-wrapper aba-up-wrapper">
              <div className="aba-popup-content">
                {abaCardIframeLink && (
                  <iframe
                    id="aba-iframe"
                    onLoad={(e) => {
                      //checkRecentOrder();
                    }}
                    style={{ width: "100%", height: "100vw" }}
                    src={abaCardIframeLink}
                  />
                )}
              </div>
            </div>
          </aside>
          {/* ABA PG popup end */}  
{/* ABA link popup start */}  
          {/* <aside
            data-pushbar-id="bottom2"
            className={`pushbar from_bottom ht100 ${
              pushbar?.abalinkPopup ? "opened" : ""
            }`}
          >
            <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
        <div className="bg-gray-900 text-white rounded-2xl p-4 w-80 shadow-lg">
          <h2 className="text-lg font-semibold mb-3 text-center">
            Add New Payment Method
          </h2>
          <div className="flex items-center justify-between bg-gray-800 p-3 rounded-xl">
            <div className="flex items-center">
              <img
                src="https://via.placeholder.com/40" // Replace with actual logo URL
                alt="ABA Pay"
                className="w-10 h-10 rounded-full mr-3"
              />
              <span className="text-base">ABA Pay</span>
            </div>
            <button
              className="text-blue-400 font-semibold"
              onClick={() => setIsOpen(false)}
            >
              Link
            </button>
          </div>
          <div className="flex justify-center mt-4">
            <button
              className="bg-red-500 text-white px-4 py-2 rounded-lg"
              onClick={() => {
                setIsOpen(false);
                onClose && onClose();
              }}
            >
              Close
            </button>
          </div>
        </div>
      </div>
          </aside> */}
<aside 
  data-pushbar-id="bottom2"
  className={`pushbar from_bottom fixed inset-0 bg-black bg-opacity-50 flex justify-center items-end transition-all duration-300 pushbar-aside ${
    pushbar?.abalinkPopup ? "opened" : "opacity-0 pointer-events-none"
  }`}
>
  <div className="bg-gradient-to-t from-black to-gray-800 text-white rounded-t-2xl p-5 w-[92%] max-w-md shadow-lg pushbar-content">
    
    {/* Title */}
    <h2 className="text-lg font-semibold text-center pt-4 pb-4">
      Add New Payment Method
    </h2>

    {/* Payment Option Card */}
 <div className="payment-box flex1 pay-methods">
                            <div className="pay-mode-icon-box">
                              <img
                                alt="paymodeimg"
                                src={
                                  process.env.REACT_APP_ASSETS_URL +
                                  "/mobile/pleng/images/ABA-pay.png"
                                }
                                className="pay-mode-img"
                              />
                            </div>
                            <div className="pay-moffer-box">
                              <span className="paym-name">
                                {textData?.pleng_payment_aba_pay}
                              </span>
                              <br />
                              <span className="aba-subtext">
                                Tap to pay with ABA mobile
                              </span>
                            </div>
                            <div className="pay-mnext-arrowbox">
                              <div className="pay-mnext-arrow">
                                Link
                                {/* <img
                                  alt="nextarrow"
                                  src={
                                    process.env.REACT_APP_ASSETS_URL +
                                    "/mobile/pleng/images/next-arrow.svg"
                                  }
                                /> */}
                              </div>
                            </div>
                          </div>
    {/* Bottom Swipe Indicator */}
    <div className="flex justify-center mt-3">
      <div className="w-14 h-1 bg-gray-500 rounded-full"></div>
    </div>
  </div>
</aside>

          {/* ABA link popup end */}  
        </>
      )}
    </>
  );
};
