import React, { useEffect, useState,useRef, use } from "react";
import ReactGA from "react-ga";
import axios from "axios";
import crypto from "crypto-browserify";
import { useLocation, useHistory } from "react-router-dom";
import { useTimer } from "react-timer-hook";
import {
  amplitudeBtnEvent,
  ampRabbitMQBtnEvent,
  amplitudePopUpEvent,
  ampRabbitMQPopUpEvent,
  getParam,
} from "../../utils";

const Upipayments = ({
  plan_id,
  utm_source,
  aff_id,
  planData,
  uL,
  identity,
  product_id,
  platform_id,
  textData,
  upiPayments,
  urlparams,
  coupon_code,
  pushbar,
  setPushbar,
  tabHideShow,
  setTabHideShow,
  appVersion,
  buildNumber,
  isFreeTrial,
  jusPayUser,
  showConfirmationPopup,
  state
}) => {
  let currenturl = useLocation().search;
  urlparams = currenturl.replace("?", "");
  // console.log('inside upi payments',planData);
  const [upiID, setUpiID] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [selectedState, setSelectedState] = useState("Maharashtra");
  const dropdownRef = useRef(null);
  const [dropdownPosition, setDropdownPosition] = useState("bottom");
  const states = [
    "Andhra Pradesh", "Arunachal Pradesh", "Assam", "Bihar", "Chhattisgarh", 
    "Goa", "Gujarat", "Haryana", "Himachal Pradesh", "Jharkhand", 
    "Karnataka", "Kerala", "Madhya Pradesh", "Maharashtra", "Manipur", 
    "Meghalaya", "Mizoram", "Nagaland", "Odisha", "Punjab", 
    "Rajasthan", "Sikkim", "Tamil Nadu", "Telangana", "Tripura", 
    "Uttar Pradesh", "Uttarakhand", "West Bengal"
  ];

  // useEffect(() => {
  //   fetch("https://ipinfo.io/json?token=93335b04fbb0f3")
  //     .then((response) => response.json())
  //     .then((data) => {
  //       if (data.region) {
  //         console.log('data.region',data.region);
  //         setSelectedState(data.region);
  //       }
  //     })
  //     .catch((error) => console.error("Error fetching location:", error));
  // }, []);

  useEffect(() => {
    console.log('state',state);
      if (state != null && state != "" && state != undefined) {
        setSelectedState(state);
      } else {      
        fetch("https://transaction.api.hungama.com/v1/transaction/ipdetails")
        .then((response) => response.json())
        .then((data) => {
          if (data.region) {
            console.log('data.region',data.region);
            setSelectedState(data.region);
          }
        })
        .catch((error) => console.error("Error fetching location:", error));
      }
  },[]);      
  useEffect(() => {
    if (isOpen && dropdownRef.current) {
      const rect = dropdownRef.current.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      const spaceBelow = windowHeight - rect.bottom;
      const spaceAbove = rect.top;

      if (spaceBelow < 180 && spaceAbove > spaceBelow) {
        setDropdownPosition("top"); // Open upwards if not enough space below
      } else {
        setDropdownPosition("bottom"); // Open downwards if enough space
      }
    }
  }, [isOpen]);

  useEffect(() => {
  // Basic validation for UPI ID
  const isValid = /^[\w.-]+@[\w]+$/.test(upiID);
  setIsUpiValid(isValid);
}, [upiID]);
useEffect(() => {
  if (!showConfirmationPopup) {
    setShowUPIError(false); // ✅ Reset error when popup closes
  }
}, [showConfirmationPopup]);
  const [isLoading, setLoading] = useState(true);
  // const fetchData = async () => {
  //     console.log('Data received:', data);
  //     console.log('upipayments',upiPayments); 
  //     upiPayments = upiPayments.filter(item1 => {
  //       const match = data.find(item2 => item2.upi_type === item1.upi_type);
  //       if (match) {
  //         item1.url = match.url;  // Add new property 'age' to the item in array1
  //         item1.order_id = match.order_id
  //         return true
  //       }
  //       else{
  //         return false
  //       }
  //     });
  //     console.log('final upipayments',upiPayments);
  // };
  // const intentDataNew = async (data) => {
  //   console.log('inside this function');
  //   let res = await axios({
  //     method: "GET",
  //     url: `${process.env.REACT_APP_HANGAMA_URL}/billing/pay_juspay_intent.php?upi_type=gpay&payment_id=${plan_id}&plan_details_id=${data[0].plan_details_id}&pg=UPI&bankcode=INTENT&type=upi_intent&${urlparams}&coupon_code=${coupon_code}&juspay_identity=${jusPayUser.juspay_identity}&hid=${identity}&frequency=${planData.plan_name}&og_price=${planData.original_price}&plan_price=${planData.plan_price}`,
  //   })
  //     .then((res) => {
  //       console.log('final data',res);
  //       setLoading(false);
  //       const response = res?.data;
  //       return response;
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  //     return res;
  //     };
      
  const upi = "https://images1.hungama.com/p/https://assets-pay.hungama.com/assets/un/mobile/images/upi.svg";
  const gpay =
    "https://images1.hungama.com/p/https://assets-pay.hungama.com/assets/un/mobile/images/gpay.svg";
  const paytm =
    "https://images1.hungama.com/p/https://assets-pay.hungama.com/assets/un/mobile/images/paytm.svg";
  const phonepay =
    "https://images1.hungama.com/p/https://assets-pay.hungama.com/assets/un/mobile/images/phonepay.svg";
const bhim = "https://assets-pay.hungama.com/assets/un/mobile/images/bim.svg" 

  const [time, setTime] = useState();

  // const ref = useRef();
  let ulLowerCase = uL?.toLowerCase();
  //PhonePe remove on build number condition
  /*
  As per teams call enabling PhonePe for intent by commenting this block
  let upiListArr = ulLowerCase?.split(",");
  if (ulLowerCase !== "" && parseInt(buildNumber) < 511) {
    //remove phonepay
    upiListArr = upiListArr?.filter((el) => el !== "phonepe");
    ulLowerCase = upiListArr?.join();
  }
  */
  // console.log("ulLowerCase::", ulLowerCase);

  // const [pushbar, setPushbar] = useState({ openCoupon: false, Verifybottom1: false, Verifybottom2: false });
  const [isUpiValid, setIsUpiValid] = useState(false);
  const [upiPopName, setUpiPopName] = useState("UPI App");
  const [logoType, setLogoType] = useState(upi);
  // const [isTimer, setIsTimer] = useState(false);
  const [showUPIError, setShowUPIError] = useState(false);
  // const [plan, setPlan] = useState({ discountPrice: " " });
  const [txnId, setTxnId] = useState("");

  const [isUpiBtnDisable, setUpiBtnDisable] = useState(false);
  const [isAgree, setIsAgree] = useState(true);

  /*
     //control autopay checkbox for users
    let allowCheckConsent=[];
    if(allowCheckConsent.includes(identity) && planType!=="event" && !/vod/.test(planType) ){
        isValidUser=true;
    }
    */
  let isValidUser = false;
  const planType = getParam(urlparams, "plan_type").toLocaleLowerCase();
  /* exclude autopay consent checkbox for 3 days plan i.e plan id 57 */
  if (
    planType !== "event" &&
    !/vod/.test(planType) &&
    plan_id !== "57" &&
    plan_id !== "10"
  ) {
    isValidUser = true;
  }

  const TRACKING_ID = `${process.env.REACT_APP_GA_CODE_PWA}`; // OUR_TRACKING_ID
  ReactGA.initialize(TRACKING_ID);
  const useAnalyticsEventTracker = (category = "Payment Init") => {
    const eventTracker = (action = "action init", label = "label init") => {
      ReactGA.event({ category, action, label });
    };
    return eventTracker;
  };

//   const setOrderId = async(identity,order_id) =>{
//     let res = await axios({
//       method: "GET",
//       url: `${process.env.REACT_APP_HANGAMA_URL}/billing/juspay/set_orderid_upi_intent.php?identity=${identity}&order_id=${order_id}`,
// })
//       .then((res) => {
//         console.log('response',res);
//       })
//       .catch((e) => {
//         console.log('error in setOrderId',e);
//       });
//   }

  const verifyText = (textData, isUpiValid = false) => {
    let text = "";
    if (isUpiValid) {
      // if (textData?.secondLang?.pay_verified) {
      //   text = textData?.secondLang?.pay_verified;
      // } else {
      //   text = textData?.default?.pay_verified;
      // }
      text = textData?.pay_verified;
    } else {
      // if (textData?.secondLang?.pay_verify) {
      //   text = textData?.secondLang?.pay_verify;
      // } else {
      //   text = textData?.default?.pay_verify;
      // }
      text = textData?.pay_verify;
    }
    return text;
  };
  // console.log("child Upi = " + coupon_code);

  const handlePay = (e) => {
    // amplitudeBtnEvent(
    //   identity,
    //   "Btn_PayNowConfirmation_UPI",
    //   product_id,
    //   platform_id
    // );
    ampRabbitMQBtnEvent(
      {
        identity: identity,
        product_id: product_id,
        platform_id: platform_id,
      },
      "Btn_PayNowConfirmation_UPI"
    );
    /*  
    amplitudePopUpEvent(
      identity,
      {
        Source: utm_source,
        "Plan Name": planData?.plan_name,
        "Plan Validity": planData?.plan_valid,
        "Plan Value": planData?.plan_price?.toFixed(2),
        "Plan Currency": planData?.plan_currency,
        "Plan ID": plan_id,
        "Affiliate ID": aff_id,
        Action: "UPI_Timer",
      },
      product_id,
      platform_id
    );
    */
    ampRabbitMQPopUpEvent({
      identity: identity,
      Source: utm_source,
      "Plan Name": planData?.plan_name,
      "Plan Validity": planData?.plan_valid,
      "Plan Value": planData?.plan_price?.toFixed(2),
      "Plan Currency": planData?.plan_currency,
      "Plan ID": plan_id,
      "Affiliate ID": aff_id,
      product_id: product_id,
      platform_id: platform_id,
      Action: "UPI_Timer",
    });

    const payment_id = e?.target?.getAttribute("payment_id");
    gaEventTracker("pay_now", planData?.plan_name + "_" + planData?.plan_price);
    console.log('inside this function===>',e.target)
    const plan_details_id = e?.target?.getAttribute("plan_details_id");
    let jusPayIdentity = jusPayUser ? jusPayUser.juspay_identity : '';
    /* const urlCreateRequest = `${process.env.REACT_APP_HANGAMA_URL}/billing/pay.php?product_id=${product_id}&payment_id=${payment_id}&plan_details_id=${plan_details_id}&pg=UPI&bankcode=UPI&type=upi_collect&vpa=${formData?.upiid}&${urlparams}&coupon_code=${coupon_code}`; */
    const urlCreateRequest = `${process.env.REACT_APP_HANGAMA_URL}/billing/pay_juspay_intent_flow.php?product_id=${product_id}&plan_id=${planData.plan_id}&payment_id=${payment_id}&plan_details_id=${plan_details_id}&pg=UPI&bankcode=UPI&type=upi_collect&vpa=${formData?.upiid}&${urlparams}&coupon_code=${coupon_code}&juspay_identity=${jusPayIdentity}&hid=${identity}&frequency=${jusPayUser?.plan_info?.plan_name}&og_price=${jusPayUser?.plan_info?.original_price}&plan_price=${planData.plan_price}&state=${selectedState}`;
    handleElement(e);
    // setIsTimer(true);
    setPushbar((prev) => {
      return {
        ...prev,
        Verifybottom1: false,
        isTimer: true,
      };
    });
    axios
      .get(urlCreateRequest)
      .then((res) => {
        console.log("handle pay::", res);
        if (
          res?.data?.result?.status === "pending" &&
          res?.data?.result?.txnid !== ""
        ) {
          setTxnId(res?.data?.result?.txnid);
          setTimeout(() => {
            checkUpiPaymentStatus(
              false,
              payment_id,
              plan_details_id,
              res?.data?.result?.txnid
            );
          }, 4000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const MyTimer = ({ expiryTimestamp }) => {
    const { seconds, minutes } = useTimer({
      expiryTimestamp,
      onExpire: () => {
        // console.warn("onExpire called");
        console.log('==on time expire==', `${process.env.REACT_APP_HANGAMA_URL}/billing/response_juspay.php?src=upi&payment_id=${upiPayments[0]?.payment_id}&product_id=${product_id}&status=pending&order_id=${txnId}&plan_details_id=${upiPayments[0]?.plan_details_id}&coupon_code=${coupon_code}&identity=${identity}&state=${selectedState}`)
        window.location.href = `${process.env.REACT_APP_HANGAMA_URL}/billing/response_juspay.php?src=upi&payment_id=${upiPayments[0]?.payment_id}&product_id=${product_id}&status=pending&order_id=${txnId}&plan_details_id=${upiPayments[0]?.plan_details_id}&coupon_code=${coupon_code}&identity=${identity}&state=${selectedState}`;
      },
    });
    return (
      <>
        <span>{minutes < 10 ? "0" + minutes : minutes}</span>:
        <span>{seconds < 10 ? "0" + seconds : seconds}</span>
      </>
    );
  };

  const MyTimer2 = ({ expiryTimestamp }) => {
    const { seconds, minutes } = useTimer({
      expiryTimestamp,
      onExpire: () => {
        console.warn("onExpire called");
        setPushbar((prev) => {
          return {
            ...prev,
            Verifybottom3: false,
            isTimer2: false,
          };
        })
        // console.log('==on time expire==', `${process.env.REACT_APP_HANGAMA_URL}/billing/response_juspay.php?src=upi&payment_id=${upiPayments[0]?.payment_id}&product_id=${product_id}&status=pending&order_id=${txnId}&plan_details_id=${upiPayments[0]?.plan_details_id}&coupon_code=${coupon_code}&identity=${identity}`)
        // window.location.href = `${process.env.REACT_APP_HANGAMA_URL}/billing/response_juspay.php?src=upi&payment_id=${upiPayments[0]?.payment_id}&product_id=${product_id}&status=pending&order_id=${txnId}&plan_details_id=${upiPayments[0]?.plan_details_id}&coupon_code=${coupon_code}&identity=${identity}`;
      },
    });
    return (
      <>
        <span>{minutes < 10 ? "0" + minutes : minutes}</span>:
        <span>{seconds < 10 ? "0" + seconds : seconds}</span>
      </>
    );
  };
  /*
  const verifyCoupon = (e) => {

      if (e) e.preventDefault();

      const url = `${process.env.REACT_APP_HANGAMA_URL}/page/index.php?c=api&m=execute_coupon&coupon_code=${formData.couponcode}&${urlparams}`;
      // console.log(url);
      axios
          .get(url)
          .then((res) => {
              // console.log("=========");
              // console.log(res);
              // console.log("=========");
              const couponDiscount = get(res, 'data.coupon_details.value', "");
              // console.log("coupon discount" + couponDiscount);
              if (couponDiscount !== "" || null || undefined) {
                  setFormData((prev) => {
                      return { ...prev, couponVerified: true }
                  })
                  setPushbar((prev) => {
                      return { ...prev, openCoupon: false }
                  });
                  setPlan((prev) => {
                      return { ...prev, discountPrice: plan.plan_price - couponDiscount }
                  });
              }
          })
          .catch((err) => {
              console.log(err);
          });
  }
  */

  const handleElement = (e) => {
    const close = e?.target?.getAttribute("close");

    if (close === "close") {
      // amplitudeBtnEvent(
      //   identity,
      //   "Btn_CloseVerification_UPI",
      //   product_id,
      //   platform_id
      // );
      ampRabbitMQBtnEvent(
        {
          identity: identity,
          product_id: product_id,
          platform_id: platform_id,
        },
        "Btn_CloseVerification_UPI"
      );
    }

    if (close === "closevpa") {
      // amplitudeBtnEvent(
      //   identity,
      //   "Btn_CloseVPA_Confirmation_UPI",
      //   product_id,
      //   platform_id
      // );
      ampRabbitMQBtnEvent(
        {
          identity: identity,
          product_id: product_id,
          platform_id: platform_id,
        },
        "Btn_CloseVPA_Confirmation_UPI"
      );
    }

    const name = e?.target?.getAttribute("data-name");
    let value = e?.target?.getAttribute("value");
    const upiid = formData?.upiid;
    let upiiType = upiid?.split("@")?.pop();
    upiTypeLogo(upiiType);
    setPushbar((prev) => {
      // console.log(value);
      return { ...prev, [name]: value === "true" ? true : false }; //value === "true" ? true : false
    });
    // console.log(pushbar);
  };

  const upiTypeLogo = (upiType = "upi") => {
    if (upiType?.toLowerCase() === "upi")
      return setLogoType(upi) & setUpiPopName("UPI App");
    if (upiType?.toLowerCase() === "paytm")
      return setLogoType(paytm) & setUpiPopName("Paytm App");
    if (upiType?.toLowerCase() === "gpay")
      return setLogoType(gpay) & setUpiPopName("Gpay App");
    if (upiType?.toLowerCase() === "ybl")
      return setLogoType(phonepay) & setUpiPopName("PhonePe App");
    if (upiType?.toLowerCase() === "bhim")
      return setLogoType(bhim) & setUpiPopName("Bhim App");
  };

  const [formData, setFormData] = useState({
    // couponcode: couponcode,
    couponVerified: false,
    upiid: "",
  });

  const handleVerify = (e) => {
    // amplitudeBtnEvent(identity, "Btn_Verify_UPI", product_id, platform_id);
    ampRabbitMQBtnEvent(
      {
        identity: identity,
        product_id: product_id,
        platform_id: platform_id,
      },
      "Btn_Verify_UPI"
    );
    const payment_id = e?.target?.getAttribute("payment_id");
    const upiid = formData?.upiid;
    // console.log(planData);
    //return;
    gaEventTracker("UPI", planData?.plan_name + "_" + planData?.plan_price);
    let SECRET = process.env.REACT_APP_SECRET_PAY_API_KEY;
    let paramsString = `${payment_id}${product_id}check_vpa${identity}${upiid}${coupon_code}`;
    let finalString = SECRET + paramsString;
    let hashKey = crypto.createHash('sha256').update(finalString).digest('hex');
    const urlVerify = `${process.env.REACT_APP_HANGAMA_URL}/billing/api.php?payment_id=${payment_id}&product_id=${product_id}&rtype=check_vpa&identity=${identity}&vpa=${upiid}&coupon_code=${coupon_code}&state=${selectedState}`;
    axios
      .get(urlVerify,{
        headers: {
          'API-KEY': hashKey
        }})
      .then((res) => {
        if (res?.data?.isVPAValid == false) {
          setIsUpiValid(false);
          setShowUPIError(true);
        }
        if (res?.data?.isVPAValid) {
          setIsUpiValid(true);
          setShowUPIError(false);

          //open UPI confirm : old upi collect flow
          // handleElement(e);

          //open timer popup directly & notify user,skip confirmation popup [new upi collect flow] : 13 June 2023
          setPushbar((prev) => {
            // console.log(value);
            return { ...prev, Verifybottom2: true }; //value === "true" ? true : false
          });
          handlePay(e);
        }
      })
      .catch((err) => {
        // console.log(err);
      });
    // console.log(formData);
  };

  const handleInput = (e) => {
    const { name, value } = e?.target;
    // console.log(name);
    let data = { ...formData };
    // console.log();
    data[name] = value;
    // if (!value.includes('@')) {
    //   setIsAgree(true);
    // } else {
    //   setIsAgree(false);
    // }
    setUpiID(e.target.value)
    setFormData(data);
    setIsUpiValid(false);
  };

  const checkUpiPaymentStatus = (
    refresh,
    payment_id,
    plan_details_id,
    txnid
  ) => {
    console.log("payment_id"+payment_id+"plan_details_id"+plan_details_id+"txn"+txnid);
    if (refresh) {
      // amplitudeBtnEvent(
      //   identity,
      //   "Btn_RefreshVerification_UPI",
      //   product_id,
      //   platform_id
      // );
      ampRabbitMQBtnEvent(
        {
          identity: identity,
          product_id: product_id,
          platform_id: platform_id,
        },
        "Btn_RefreshVerification_UPI"
      );
    }
    let SECRET = process.env.REACT_APP_SECRET_PAY_API_KEY;
    let paramsString = `${payment_id}${product_id}order_status_check${txnid}${coupon_code}${identity}`;
    let finalString = SECRET + paramsString;
    let hashKey = crypto.createHash('sha256').update(finalString).digest('hex');
    const urlCheckStatus = `${process.env.REACT_APP_HANGAMA_URL}/billing/api.php?payment_id=${payment_id}&product_id=${product_id}&rtype=order_status_check&order_id=${txnid}&coupon_code=${coupon_code}&identity=${identity}&state=${selectedState}`;
    axios
      .get(urlCheckStatus,{
        headers: {
          'API-KEY': hashKey
        }})
      .then((res) => {
        console.log('urlCheckStatus',res);
        // console.log();
        if (res?.data?.transaction_details[txnid]?.status === "failure") {
          console.log('==on failure==',`${process.env.REACT_APP_HANGAMA_URL}/billing/response_juspay.php?src=upi&payment_id=${payment_id}&product_id=${product_id}&status=failure&order_id=${txnid}&plan_details_id=${plan_details_id}&coupon_code=${coupon_code}&identity=${identity}&state=${selectedState}`)
          window.location.href = `${process.env.REACT_APP_HANGAMA_URL}/billing/response_juspay.php?src=upi&payment_id=${payment_id}&product_id=${product_id}&status=failure&order_id=${txnid}&plan_details_id=${plan_details_id}&coupon_code=${coupon_code}&identity=${identity}&state=${selectedState}`;
        } else if (
          res?.data?.transaction_details[txnid]?.status === "success"
        ) {
          /*
          amplitudePopUpEvent(
            identity,
            {
              Source: utm_source,
              "Plan Name": planData?.plan_name,
              "Plan Validity": planData?.plan_valid,
              "Plan Value": planData?.plan_price?.toFixed(2),
              "Plan Currency": planData?.plan_currency,
              "Plan ID": plan_id,
              "Affiliate ID": aff_id,
              Action: "UPI_Confirm",
            },
            product_id,
            platform_id
          );
          */
          ampRabbitMQPopUpEvent({
            identity: identity,
            Source: utm_source,
            "Plan Name": planData?.plan_name,
            "Plan Validity": planData?.plan_valid,
            "Plan Value": planData?.plan_price?.toFixed(2),
            "Plan Currency": planData?.plan_currency,
            "Plan ID": plan_id,
            "Affiliate ID": aff_id,
            product_id: product_id,
            platform_id: platform_id,
            Action: "UPI_Confirm",
          });
          console.log('==on success==',`${process.env.REACT_APP_HANGAMA_URL}/billing/response_juspay.php?src=upi&payment_id=${payment_id}&product_id=${product_id}&status=success&order_id=${txnid}&plan_details_id=${plan_details_id}&coupon_code=${coupon_code}&identity=${identity}&state=${selectedState}`)
          window.location.href = `${process.env.REACT_APP_HANGAMA_URL}/billing/response_juspay.php?src=upi&payment_id=${payment_id}&product_id=${product_id}&status=success&order_id=${txnid}&plan_details_id=${plan_details_id}&coupon_code=${coupon_code}&identity=${identity}&state=${selectedState}`;
        } else if (
          res?.data?.transaction_details[txnid]?.status === "pending"
        ) {
          setTimeout(() => {
            checkUpiPaymentStatus(false, payment_id, plan_details_id, txnid);
          }, 1000);
        }
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const checkUpiPaymentStatus2 = (
    payment_id,
    plan_details_id,
    txnid
  ) => {
    console.log("payment_id"+payment_id+"plan_details_id"+plan_details_id+"txn"+txnid);
    let SECRET = process.env.REACT_APP_SECRET_PAY_API_KEY;
    let paramsString = `${payment_id}${product_id}order_status_check${txnid}${coupon_code}${identity}`;
    let finalString = SECRET + paramsString;
    let hashKey = crypto.createHash('sha256').update(finalString).digest('hex');
    const urlCheckStatus = `${process.env.REACT_APP_HANGAMA_URL}/billing/api.php?payment_id=${payment_id}&product_id=${product_id}&rtype=order_status_check&order_id=${txnid}&coupon_code=${coupon_code}&identity=${identity}&state=${selectedState}`;
    axios
      .get(urlCheckStatus,{
        headers: {
          'API-KEY': hashKey
        }})
      .then((res) => {
        console.log('urlCheckStatus',res);
        if (res?.data?.transaction_details[txnid]?.status === "failure") {
          console.log('==on failure==',`${process.env.REACT_APP_HANGAMA_URL}/billing/response_juspay.php?src=upi&payment_id=${payment_id}&product_id=${product_id}&status=failure&order_id=${txnid}&plan_details_id=${plan_details_id}&coupon_code=${coupon_code}&identity=${identity}&state=${selectedState}`)
          window.location.href = `${process.env.REACT_APP_HANGAMA_URL}/billing/response_juspay.php?src=upi&payment_id=${payment_id}&product_id=${product_id}&status=failure&order_id=${txnid}&plan_details_id=${plan_details_id}&coupon_code=${coupon_code}&identity=${identity}&state=${selectedState}`;
        } else if (
          res?.data?.transaction_details[txnid]?.status === "success"
        ) {
          ampRabbitMQPopUpEvent({
            identity: identity,
            Source: utm_source,
            "Plan Name": planData?.plan_name,
            "Plan Validity": planData?.plan_valid,
            "Plan Value": planData?.plan_price?.toFixed(2),
            "Plan Currency": planData?.plan_currency,
            "Plan ID": plan_id,
            "Affiliate ID": aff_id,
            product_id: product_id,
            platform_id: platform_id,
            Action: "UPI_Confirm",
          });
          console.log('==on success==',`${process.env.REACT_APP_HANGAMA_URL}/billing/response_juspay.php?src=upi&payment_id=${payment_id}&product_id=${product_id}&status=success&order_id=${txnid}&plan_details_id=${plan_details_id}&coupon_code=${coupon_code}&identity=${identity}&state=${selectedState}`)
          window.location.href = `${process.env.REACT_APP_HANGAMA_URL}/billing/response_juspay.php?src=upi&payment_id=${payment_id}&product_id=${product_id}&status=success&order_id=${txnid}&plan_details_id=${plan_details_id}&coupon_code=${coupon_code}&identity=${identity}&state=${selectedState}`;
        } else if (
          res?.data?.transaction_details[txnid]?.status === "pending"
        ) {
          setTimeout(() => {
            checkUpiPaymentStatus2(payment_id, plan_details_id, txnid);
          }, 1000);
        }
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const intentData = async (pg, bankcode, upi_type, data) => {
    // const body = {
    //     "product_id": product_id,
    //     "platform_id": platform_id,
    //     "country": country,
    //     "plan_id": plan_id,
    //     "plan_type": plan_type,
    //     "content_id": content_id
    // };
    console.log('==payloadp==',pg,bankcode, upi_type, data)
    let jusPayIdentity = jusPayUser ? jusPayUser.juspay_identity : '';
    let res = await axios({
      method: "GET",
      /* url: `${process.env.REACT_APP_HANGAMA_URL}/billing/pay.php?upi_type=${upi_type}&payment_id=${data?.payment_id}&plan_details_id=${data?.plan_details_id}&pg=${pg}&bankcode=${bankcode}&type=upi_intent&${urlparams}&coupon_code=${coupon_code}`, */
      url: `${process.env.REACT_APP_HANGAMA_URL}/billing/pay_juspay_intent_flow.php?upi_type=${upi_type}&plan_id=${planData.plan_id}&payment_id=${data?.payment_id}&plan_details_id=${data?.plan_details_id}&pg=${pg}&bankcode=${bankcode}&type=upi_intent&${urlparams}&coupon_code=${coupon_code}&juspay_identity=${jusPayIdentity}&hid=${identity}&frequency=${jusPayUser?.plan_info?.plan_name}&og_price=${jusPayUser?.plan_info?.original_price}&plan_price=${jusPayUser?.plan_info?.plan_price}&state=${selectedState}`,
    })
      .then((res) => {
        // console.log(res);
        const response = res?.data;
        return response;
      })
      .catch((e) => {
        console.log('err->',e);
      });

    return res;
  };

  const intenFlow = async (e, data) => {
    const payment_id = data?.payment_id;
    const plan_details_id = data?.plan_details_id;
    const upi_type = data?.upi_type.toLowerCase();

    console.log('upiType == ?',upi_type)
    if(upi_type == 'phonepe'){
      upiTypeLogo('ybl')
    }
    upiTypeLogo(upi_type)

        setPushbar((prev) => {
      console.log('inside pushbar')
      return {
        ...prev,
        Verifybottom3: true,
        isTimer2: true,
      };
    });
    // console.log(data);
    // amplitudeBtnEvent(identity, "Btn_Submit_UPI", product_id, platform_id);
    ampRabbitMQBtnEvent(
      {
        identity: identity,
        product_id: product_id,
        platform_id: platform_id,
      },
      "Btn_Submit_UPI"
    );


    let response = {};
    // if (upi_type === "bhim") {
    //     response.type = "UPI";
    //     response.bankcode = "INTENT";
    // } else if (upi_type === "gpay") {
    //     response.type = "TEZ";
    //     response.bankcode = "TEZ";
    // } else if (upi_type === "phonepe") {
    //     response.type = "PPIN";
    //     response.bankcode = "INTENT";
    // }
    response.type = "UPI";
    response.bankcode = "INTENT";

    console.log('response',response);
    const intentRes = await intentData(
      response.type,
      response.bankcode,
      upi_type,
      data
    );

    const url = intentRes?.url ? intentRes?.url : "";
    const status = intentRes?.status ? intentRes?.status : "";
    const order_id = intentRes?.order_id ? intentRes?.order_id : "";
    console.log('=============>',intentRes);
    if (status === "success") {
      setTimeout(() => {
        checkUpiPaymentStatus(false, payment_id, plan_details_id, order_id);
      }, 2000);
      window.location.href = url;
    }

  };

  let isUpiFound = false;
  upiPayments
    .sort((a, b) => parseFloat(a?.display_order) - parseFloat(b?.display_order))
    .forEach((data) => {
      if (ulLowerCase?.includes(data?.payment_name?.toLowerCase())) {
        isUpiFound = true;
      }
    });
  const gaEventTracker = useAnalyticsEventTracker("UPI Payment");

  // useEffect(()=>{
  //     if(pushbar.Verifybottom1){
  //         document.body.style.overflow = 'hidden';
  //     }
  //     else if(pushbar.Verifybottom2){
  //         document.body.style.overflow = 'hidden';
  //     }
  //     else {
  //         document.body.style.overflow = '';
  //     }
  // },[pushbar])
  // useEffect(() => {
    // console.log('inside useEffect ====>');
    // fetchData();
  // },[])

  useEffect(() => {
    // console.log('timer::',isTimer)
    if (pushbar?.Verifybottom2 && pushbar?.isTimer) {
      const time = new Date();
      time.setSeconds(time?.getSeconds() + 300); // 10 minutes timer
      setTime(time);
    }
  }, [pushbar?.Verifybottom2, pushbar?.isTimer]);

  useEffect(() => {
    // console.log('timer::',isTimer)
    if (pushbar?.Verifybottom3 && pushbar?.isTimer2) {
      const time = new Date();
      time.setSeconds(time?.getSeconds() + 300); // 10 minutes timer
      setTime(time);
    }
  }, [pushbar?.Verifybottom3, pushbar?.isTimer2]);

  return (
    <>
      {upiPayments && upiPayments?.length > 0 && (
        <div className="pay-mode-box">
          <div
            class="cchead flex1"
            // onClick={(e) =>
            //   setTabHideShow((prevState) => ({
            //     ...prevState,
            //     upiTab: !tabHideShow?.upiTab,
            //     ccTab: false,
            //   }))
            // }
          >
            <p
              className="p-t1"
              // onClick={(e) =>
              //   setTabHideShow((prevState) => ({
              //     ...prevState,
              //     upiTab: !tabHideShow?.upiTab,
              //     ccTab: false,
              //   }))
              // }
              style={{ color: "white" }}
            >
              {/* Select Your Upi App */}
              {textData?.pay_tab_upi_title}
            </p>
            {/* <div class="down-arrow pointer">
              <img
                onClick={(e) =>
                  setTabHideShow((prevState) => ({
                    ...prevState,
                    upiTab: !tabHideShow?.upiTab,
                    ccTab: false,
                  }))
                }
                src={
                  process.env.REACT_APP_ASSETS_URL +
                  "/mobile/images/down-arrow.svg"
                }
                alt="downarrow"
              />
            </div> */}
          </div>

          {
            //free trial check added
            isFreeTrial?.status === false &&
            isFreeTrial?.plan_id === plan_id ? (
              ""
            ) : uL !== "" && upiPayments && upiPayments?.length > 0 ? (
              <div className="other-payment">
                {upiPayments
                  ?.sort(
                    (a, b) =>
                      parseFloat(a?.display_order) -
                      parseFloat(b?.display_order)
                  )
                  .map(
                    (data, i) =>
                      ulLowerCase?.includes(
                        data?.payment_name?.toLowerCase()
                      ) && (
                        <div
                          className="other-payment-box"
                          key={i}
                          onClick={(e) => intenFlow(e, data)}
                          // onClick={(e) => {if (data.url) {
                          //   console.log('<====dataURL===>',data)
                          //   checkUpiPaymentStatus2(data.payment_id,data.plan_details_id,data.order_id)
                          //   setOrderId(identity,data.order_id);
                          //   window.location.href = data.url;
                          // } else {
                          //   intenFlow(e, data)
                          // }}}
                        >
                          <div className="pointer">
                              <img
                              src={data?.payment_logo}
                              alt="paytm"
                              className="pay-mode-img"
                            />
                           
                          </div>
                          <p className="other-payment-name">
                            <a
                              // href="/#"
                              onClick={(e) => {
                                e.preventDefault();
                                intenFlow(e, data);
                              }}
                              // onClick={() => {if (data.url) {
                              //   console.log('dataURL===>',data.url)
                                // window.location.href = data.url;
                              // } else {
                              //   alert("No valid URL found!");
                              // }}}
                              className="pointer"
                            >
                              {data?.payment_name}
                            </a>
                          </p>
                        </div>
                      )
                  )}
              </div>
            ) : (
              ""
            )
          }
          <div style={{ display: !tabHideShow?.upiTab ? "none" : "block" }}>
  {isUpiFound && upiPayments?.length > 0 && (
    <div className="or-titlebox">
      <h3>{textData?.pay_enter_upi_mob}</h3>
    </div>
  )}

  {/* UPI Input and Verify Button in a single box */}
  <div
    className="upi-search-container"
    style={{
      display: "flex",
      alignItems: "center",
      backgroundColor: "#2A2D3A", // Dark background
      borderRadius: "30px", // Rounded edges
      padding: "10px 15px",
      width: "100%", // Full width
      maxWidth: "400px", // Adjust based on design
      border: "1px solid #4A4F63",
      marginTop: "15px" // Optional border
    }}
  >
    <input
      autoComplete="off"
      className="upiidsearch"
      type="text"
      placeholder="UPI ID (username@bankname)"
      onChange={(e) => handleInput(e)}
      name="upiid"
      style={{
        flex: "1", // Takes available space
        background: "transparent",
        border: "none",
        outline: "none",
        color: "white",
        fontSize: "14px",
      }}
    />

    {/* Verify Button */}
    <button
      className="verify-button"
      plan_details_id={
        upiPayments[0]?.plan_details_id &&
        upiPayments[0]?.plan_details_id
      }
      payment_id={
        upiPayments[0]?.payment_id && upiPayments[0]?.payment_id
      }
      onClick={(e) => handleVerify(e)}
      style={{
        backgroundColor: "#4A90E2", // Blue button
        color: "white",
        border: "none",
        padding: "8px 15px",
        borderRadius: "20px",
        fontWeight: "bold",
        fontSize: "14px",
        cursor: "pointer",
      }}
    >
      VERIFY
    </button>
  </div>

  {showUPIError && (
    <p
      style={{
        color: "#FA3A59",
        marginTop: "6px",
        fontSize: "3.7vw",
      }}
    >
      {textData?.pay_invalid_upi}
    </p>
  )}

  {/* Consent Checkbox */}
  {isValidUser && (
    <div className="agree-row">
      <label>
        <input
          onChange={(e) => setIsAgree(e.target.checked)}
          type="checkbox"
          name="checkbox"
          checked={isAgree}
        />
        {textData?.pay_consent}
      </label>
      {!isAgree && <p className="error-msg">{textData?.pay_consent_error}</p>}
    </div>
  )}

<div style={{ marginTop: "15px", textAlign: "left", position: "relative" }}>
<div style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}>
    {/* Info Icon */}
    <span
      style={{
        fontSize: "14px",
        color: "#4A90E2", // Blue color
        marginRight: "6px",
      }}
    >
      ⓘ
    </span>

    {/* Label */}
    <label
      style={{
        fontSize: "14px",
        fontWeight: "500",
        color: "#4A90E2", // Blue color for label
        cursor: "pointer",
      }}
    >
      GST Information
    </label>
  </div>

      {/* Custom Dropdown */}
      <div
        onClick={() => setIsOpen(!isOpen)}
        style={{
          width: "100%",
          backgroundColor: "#333",
          borderRadius: "8px",
          padding: "10px",
          color: "#fff",
          fontSize: "14px",
          cursor: "pointer",
          position: "relative",
          border: "1px solid #444",
        }}
      >
        {selectedState}
        <span
          style={{
            position: "absolute",
            right: "10px",
            top: "50%",
            transform: "translateY(-50%)",
            pointerEvents: "none",
            color: "#bbb",
          }}
        >
          ▼
        </span>
      </div>

      {/* Dropdown Options */}
      {isOpen && (
        <ul
          ref={dropdownRef}
          style={{
            listStyle: "none",
            padding: "0",
            margin: "5px 0 0",
            backgroundColor: "#222",
            borderRadius: "8px",
            border: "1px solid #444",
            position: "absolute",
            width: "100%",
            zIndex: 100,
            maxHeight: "180px", // Limit height to avoid overflow
            overflowY: "auto", // Enable scrolling
            bottom: dropdownPosition === "top" ? "100%" : "auto",
            top: dropdownPosition === "bottom" ? "100%" : "auto",
          }}
        >
          {states.map((state, index) => (
            <li
              key={index}
              onClick={() => {
                setSelectedState(state);
                setIsOpen(false);
              }}
              style={{
                padding: "10px",
                cursor: "pointer",
                color: "#fff",
                fontSize: "14px",
                transition: "background 0.3s",
              }}
              onMouseOver={(e) => (e.target.style.background = "#444")}
              onMouseOut={(e) => (e.target.style.background = "transparent")}
            >
              {state}
            </li>
          ))}
        </ul>
      )}

      {/* Helper Text */}
      <p
        style={{
          fontSize: "3.2vw",
          color: "#aaa",
          marginTop: "5px",
          fontWeight: "400"
        }}
      >
        *Please select your current state as required by government guidelines
      </p>
    </div>
</div>

        </div>
      )}

      {/* verify popup */}
      <aside
        data-pushbar-id="bottom1"
        style={{ pointerEvents: pushbar?.Verifybottom1 ? "auto" : "none" }}
        className={
          pushbar?.Verifybottom1
            ? "pushbar from_bottom ht75 opened"
            : "pushbar from_bottom ht75"
        }
      >
        <div className="pop-up-wrapper">
          <div className="upi-popup-content">
            <div className="p-paymenticon">
              <img alt="logo type" src={logoType} className="p-payicon" />
            </div>
            <p className="p-txt1">{textData?.pay_poup_pay_with}</p>
            <p className="p-upiid">{formData?.upiid}</p>
            <div className="p-paybtnbox">
              <div
                className="grey-button m2"
                data-pushbar-close
                close="close"
                data-name="Verifybottom1"
                value={false}
                onClick={(e) => handleElement(e)}
              >
                {/* <img alt="close icon" src={process.env.REACT_APP_ASSETS_URL + "/mobile/images/close-icon.svg"} /> */}
                {/* Close */}
                {textData?.pay_popup_close}
              </div>
              <div
                style={{ pointerEvents: isUpiBtnDisable ? "none" : "auto" }}
                className="button-blue d-inline-flex button-pay-now m2"
                data-name="Verifybottom2"
                value="true"
                plan_details_id={
                  upiPayments &&
                  upiPayments[0]?.plan_details_id &&
                  upiPayments[0]?.plan_details_id
                }
                payment_id={
                  upiPayments &&
                  upiPayments[0]?.payment_id &&
                  upiPayments[0]?.payment_id
                }
                onClick={(e) => {
                  handlePay(e);
                  setUpiBtnDisable(true);
                }}
                data-pushbar-target="bottom2"
              >
                {/* <img alt="pay now" src={process.env.REACT_APP_ASSETS_URL + "/mobile/images/paynow.svg"} className="button-icon" /> */}
                {textData?.pay_paynow}
              </div>
            </div>
            <div className="p-securebox">
              <img
                src={
                  process.env.REACT_APP_ASSETS_URL +
                  "/mobile/images/lock-icon.svg"
                }
                alt="lock icon"
                className="lockicon"
              />
              {textData?.pay_secure_text}
            </div>
          </div>
        </div>
      </aside>

      {/* timer popup */}
      <aside
        data-pushbar-id="bottom2"
        style={{
          pointerEvents:
            pushbar?.Verifybottom2 && pushbar?.isTimer === true
              ? "auto"
              : "none",
        }}
        className={
          pushbar?.Verifybottom2 && pushbar?.isTimer === true
            ? "pushbar from_bottom ht83 opened"
            : "pushbar from_bottom ht83"
        }
      >
        <div className="pop-up-wrapper">
          <div className="upi-popup-content">
            <div className="p-paymenticon">
              <img src={logoType} className="p-payicon" alt="logo type" />
            </div>
            <p className="p-txt1">
              {textData?.pay_open} {upiPopName}
            </p>
            <p className="p-verify-status">
              {/* Please wait. Verifying payment status */}
              {textData?.pay_popup_please_wait}
            </p>
            <div className="p-timmer-box">
              {pushbar?.Verifybottom2 && pushbar?.isTimer === true ? (
                <MyTimer expiryTimestamp={time} />
              ) : (
                ""
              )}
            </div>
            <div className="p-paybtnbox">
              <div
                className="grey-button m2"
                data-pushbar-close
                close="closevpa"
                data-name="Verifybottom2"
                value={false}
                onClick={(e) => {
                  handleElement(e);
                  setUpiBtnDisable(false);
                }}
              >
                {/* <img alt="close icon" src={process.env.REACT_APP_ASSETS_URL + "/mobile/images/close-icon.svg"} /> */}
                {textData?.pay_popup_cancel}
              </div>
              <div
                className="button-blue m2"
                refresh="true"
                plan_details_id={
                  upiPayments &&
                  upiPayments[0]?.plan_details_id &&
                  upiPayments[0]?.plan_details_id
                }
                payment_id={
                  upiPayments &&
                  upiPayments[0]?.payment_id &&
                  upiPayments[0]?.payment_id
                }
                onClick={(e) =>
                  checkUpiPaymentStatus(
                    true,
                    e.target.getAttribute("payment_id"),
                    e.target.getAttribute("plan_details_id"),
                    txnId
                  )
                }
              >
                {/* <img alt="refresh icon" src={process.env.REACT_APP_ASSETS_URL + "/mobile/images/refresh-icon.svg"} /> */}
                {textData?.pay_popup_refresh}
              </div>
            </div>
            <div className="p-securebox">
              <img
                src={
                  process.env.REACT_APP_ASSETS_URL +
                  "/mobile/images/lock-icon.svg"
                }
                alt="lock icon"
                className="lockicon"
              />
              {textData?.pay_secure_text}
            </div>
          </div>
        </div>
      </aside>
{/* timer popup2 */}
      <aside
        data-pushbar-id="bottom2"
        style={{
          pointerEvents:
            pushbar?.Verifybottom3 && pushbar?.isTimer2 === true
              ? "auto"
              : "none",
              position: "fixed",
              top: 0,
              left: 0,
              width: "100vw",
              height: "100vh",
              background: "rgba(0, 0, 0, 0.5)", /* Optional: Adds a semi-transparent overlay */
              "z-index": "9999",
              display: "flex",
              "justify-content": "center",
              "align-items": "center"
        }}
        className={
          pushbar?.Verifybottom3 && pushbar?.isTimer2 === true
            ? "pushbar from_bottom ht83 opened"
            : "pushbar from_bottom ht83"
        }
      >
        <div className="pop-up-wrapper">
          <div className="upi-popup-content">
            <div className="p-paymenticon">
              <img src={logoType} className="p-payicon" alt="logo type" />
            </div>
            {/* <p className="p-txt1">
              {textData?.pay_open} {upiPopName}
            </p> */}
            <p className="p-verify-status">
              {/* Please wait. Verifying payment status */}
              {textData?.calm_down_box}
            </p>
            <div className="p-timmer-box">
              {pushbar?.Verifybottom3 && pushbar?.isTimer2 === true ? (
                <MyTimer2 expiryTimestamp={time} />
              ) : (
                ""
              )}
            </div>
            <div className="p-paybtnbox">
              {/* <div
                className="grey-button m2"
                data-pushbar-close
                close="closevpa"
                data-name="Verifybottom3"
                value={false}
                onClick={(e) => {
                  handleElement(e);
                  setUpiBtnDisable(false);
                }}
              >
                {textData?.pay_popup_cancel}
              </div> */}
             
            </div>
            <div className="p-securebox">
              <img
                src={
                  process.env.REACT_APP_ASSETS_URL +
                  "/mobile/images/lock-icon.svg"
                }
                alt="lock icon"
                className="lockicon"
              />
              {textData?.pay_secure_text}
            </div>
          </div>
        </div>
      </aside>
    </>
  );
};
export default Upipayments;
