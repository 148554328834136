import React, { useEffect, useRef, useState } from "react";
import ReactGA from "react-ga";

import axios from "axios";
import { useLocation, useHistory } from "react-router-dom";

import get from "lodash.get";
import {
  amplitudeBtnEvent,
  amplitudePopUpEvent,
  ampRabbitMQBtnEvent,
  ampRabbitMQPopUpEvent,
  ampRabbitMQPageEvent,
  ampRabbitMQsubBtnClickPageEvent
} from "../../utils";
import {
  language,
  updateOrderStatus,
  checkFreeTrialStatus,
} from "../../utils/services";
import { freeTrialPlanData, SUCCESS } from "../../../constants";

import Netbanking from "../netbanking";
import Payments from "../payments";
import Wallets from "../wallets";
import Upipayments from "../upi/index_juspay";
import Offers from "../offers";
import { getJusPayCustomer, get_jus_pay_identity } from "../../utils/juspay";
import { payments as paymentsPlanInfoDetails} from "../../utils/payments";
export const Payment = (props) => {
  //  GA code
  const TRACKING_ID = `${process.env.REACT_APP_GA_CODE_PWA}`; // OUR_TRACKING_ID
  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    ReactGA.pageview(window.location.pathname);
  }, [TRACKING_ID]);
  const useAnalyticsEventTracker = (category = "Payment Init") => {
    const eventTracker = (action = "action init", label = "label init") => {
      ReactGA.event({ category, action, label });
    };
    return eventTracker;
  };
  const gaEventTracker = useAnalyticsEventTracker("Coupon Code");
  const [jusPayIdentity, setjusPayIdentity] = useState({});
  useEffect( ()=>{
    setjusPayIdentity(JSON.parse(localStorage.getItem('juspay_user')));
  },[])
  // useEffect( async () => {
  //   const getJusPayUser = await get_jus_pay_identity(identity);
  //   if(getJusPayUser.message_code == 'GET_CUSTOMER_SUCCESS'){
  //       jusPayIdentity.juspay_identity = getJusPayUser.juspay_identity;
  //       jusPayIdentity.hungama_identity = getJusPayUser.identity;
  //       console.log("in credit page getUSER => ", getJusPayUser); 
  //       setjusPayIdentity({...jusPayIdentity});
  //   }
  //   else{
  //     const createJusPayUser = await getJusPayCustomer(identity);
  //     jusPayIdentity.juspay_identity = createJusPayUser.juspay_identity;
  //     jusPayIdentity.hungama_identity = createJusPayUser.identity;
  //     setjusPayIdentity({...jusPayIdentity});
  //     console.log("in credit page createUSER => ", createJusPayUser); 
  //   }
  //   const planDetail = await paymentsPlanInfoDetails({plan_id,plan_type,content_id,identity,product_id,platform_id,country});
  //   jusPayIdentity.plan_type  = plan_type;
  //   jusPayIdentity.plan_id    = plan_id;
  //   jusPayIdentity.plan_info  = planDetail.data.planInfo; 
  //   setjusPayIdentity({...jusPayIdentity}); 
  //   console.log("plan detail => ",  planDetail); 
  //   console.log("in credit page setState => ", jusPayIdentity); 
  // }, []);  
  /* Hooks */
  const ref = useRef();
  const history = useHistory();
  let currenturl = useLocation().search;
  const pathname = useLocation().pathname;
  const location = useLocation().search;
  const hostname = window.location.host;

  /* Params */
  const couponcode = new URLSearchParams(location).get("couponcode")
    ? new URLSearchParams(location).get("couponcode")
    : "";
  const auth = new URLSearchParams(location).get("auth");
  const plan_id = new URLSearchParams(location).get("plan_id");
  const state = new URLSearchParams(location).get("state");
  const product_id = new URLSearchParams(location).get("product_id");
  const platform_id = new URLSearchParams(location).get("platform_id");
  const country = new URLSearchParams(location).get("country");
  const plan_type = new URLSearchParams(location).get("plan_type");
  const identity = new URLSearchParams(location).get("identity");
  const content_id = new URLSearchParams(location).get("content_id");
  const uL = new URLSearchParams(location).get("upilist");
  const appVersion = new URLSearchParams(location).get("app_version");
  const buildNumber = new URLSearchParams(location).get("build_number")
    ? parseInt(new URLSearchParams(location).get("build_number"))
    : 0;
  const lan = new URLSearchParams(location).get("lang");
  const npay_redirect = new URLSearchParams(location).get("npay_redirect");
  const npay_campaignsource = new URLSearchParams(location).get(
    "npay_campaignsource"
  );
  const campaignsource = new URLSearchParams(location).get("campaignsource");
  const npay_affilaite = new URLSearchParams(location).get("npay_affilaite");
  const aff_id = new URLSearchParams(location).get("aff_id");
  const utm_source = new URLSearchParams(location).get("utm_source");
  const originalSource = new URLSearchParams(location).get("source");
  const urlparams = location.replace("?", "");

  /* States */
  const [plan, setPlan] = useState({ discountPrice: " " });
  const [featured, setFeatured] = useState({});
  const [payments, setPayments] = useState([]);
  const [wallets, setWallets] = useState([]);
  const [total_user_coins, setTotal_user_coins] = useState([]);
  const [netBankingPayments, setNetBankingPayments] = useState([]);
  const [upiPayments, setUpiPayments] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [newUpiList,setNewUpiList] = useState()
  const [isLoadingVerify, setisLoadingVerify] = useState(false);
  // const [defaultTextData, setDefaultTextData] = useState({});
  const [textData, setTextData] = useState({});
  const [pushbar, setPushbar] = useState({
    openCoupon: false,
    Verifybottom1: false,
    Verifybottom2: false,
    verifyRedeemCoins: false,
    verifyAmazonPay: false,
    isTimer: false,
  });
  const [isOpen, setIsOpen] = useState(false);
  const [selectedState, setSelectedState] = useState("Maharashtra");
  const dropdownRef = useRef(null);
  const [dropdownPosition, setDropdownPosition] = useState("bottom");
  const states = [
    "Andhra Pradesh", "Arunachal Pradesh", "Assam", "Bihar", "Chhattisgarh", 
    "Goa", "Gujarat", "Haryana", "Himachal Pradesh", "Jharkhand", 
    "Karnataka", "Kerala", "Madhya Pradesh", "Maharashtra", "Manipur", 
    "Meghalaya", "Mizoram", "Nagaland", "Odisha", "Punjab", 
    "Rajasthan", "Sikkim", "Tamil Nadu", "Telangana", "Tripura", 
    "Uttar Pradesh", "Uttarakhand", "West Bengal"
  ];
  const [formData, setFormData] = useState({
    couponcode: couponcode,
    couponVerified: false,
    couponError: false,
    cardHolderName: "",
    cardNumber: "",
    expiryDate: "",
    cvv: "",
    upiid: "",
  });
  const [isCouponValid, setIsCouponValid] = useState(false);
  const [tabHideShow, setTabHideShow] = useState({
    upiTab: true,
    ccTab: false,
  });
  const [freeTrialData, setFreeTrialData] = useState({});
  const [freeCouponData, setFreeCouponData] = useState({});
  const [isFreeCoupon, setIsFreeCoupon] = useState(false);
  var ua = navigator.userAgent.toLowerCase();
  var isAndroid = ua.indexOf("android") > -1;
  /* set sessionStorage for user retry with upilist */
  sessionStorage.setItem(
    "userRetryInfo",
    JSON.stringify({
      identity,
      upilist: uL,
    })
  );
  const storedWebsite = localStorage.getItem("website");
  useEffect(() => {
    setNewUpiList(uL)
  if(storedWebsite == 'true'){
    setNewUpiList("phonepe,gpay,bhim,paytm")
    console.log('newUpiList',newUpiList);
  }
}, [])

  useEffect(() => {
    console.log('state',state);
      if (state != null && state != "" && state != undefined) {
        setSelectedState(state);
      } else {      
        fetch("https://transaction.api.hungama.com/v1/transaction/ipdetails")
        .then((response) => response.json())
        .then((data) => {
          if (data.region) {
            console.log('data.region',data.region);
            setSelectedState(data.region);
          }
        })
        .catch((error) => console.error("Error fetching location:", error));
      }
  },[]); 
  
 useEffect(() => {
  // setSelectedState(state);
    if (isOpen && dropdownRef.current) {
      const rect = dropdownRef.current.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      const spaceBelow = windowHeight - rect.bottom;
      const spaceAbove = rect.top;

      if (spaceBelow < 180 && spaceAbove > spaceBelow) {
        setDropdownPosition("top"); // Open upwards if not enough space below
      } else {
        setDropdownPosition("bottom"); // Open downwards if enough space
      }
    }
  }, [isOpen]);
  /* Functions */
//redirection code for google
  // buildNumber > 546 && buildNumber < 551;
  if (buildNumber >= 547 && buildNumber <= 550) {
    // alert("buildNumber >= 547 && buildNumber <= 550", typeof buildNumber);
    let ur = window.location.search;
    if (!ur.includes("tr_id=") && ur.includes("plan_type=subscription")) {

      window.location.href = "/plan" + ur + "&yogesh=true";

    } else if (

      !ur.includes("tr_id=") &&

      !ur.includes("plan_type=subscription")

    ) {

      window.location.href = "/rent" + ur + "&yogesh=true";

    }
  } else if (buildNumber == 554) {
    alert("buildNumber == 554", typeof buildNumber);
    let ur = window.location.search;
    if (!ur.includes("tr_id=") && ur.includes("plan_type=subscription")) {
      window.location.href = "/plan" + ur + "&yogesh=true";
    } else if (
      !ur.includes("tr_id=") &&
      !ur.includes("plan_type=subscription")
    ) {
      window.location.href = "/rent" + ur + "&yogesh=true";
    }
  } else if (buildNumber > 554) {
    // let ur = window.location.search;
    // if (!ur.includes("tr_id=") && ur.includes("plan_type=subscription")) {
    //   window.location.href = "/plan" + ur + "&yogesh=true";
    // } else if (
    //   !ur.includes("tr_id=") &&
    //   !ur.includes("plan_type=subscription")
    // ) {
    //   window.location.href = "/rent" + ur + "&yogesh=true";
    // }
  }
  useEffect(() => {
    /* FREECOUPON REDIRECTION START */
    if (
      couponcode === "FREECOUPON" &&
      platform_id === "1" &&
      identity !== "" &&
      auth !== "" &&
      plan_id !== ""
    ) {
      //dont show payment page content when FREECOUPON redirection is in-process
      setIsFreeCoupon(true);
      window.location.href = `${process.env.REACT_APP_HANGAMA_URL}/billing/pay.php?payment_id=13&plan_id=${plan_id}&coupon_code=${couponcode}&identity=${identity}&auth=${auth}`;
    } else {
      setIsFreeCoupon(false);
    }
    /* FREECOUPON REDIRECTION END */
  }, [couponcode, platform_id, identity, auth, plan_id]);

  const goToPreviousPath = (contentId, planName) => {
    // amplitudeBtnEvent(identity, "Btn_Back_PG", product_id, platform_id);
    // ampRabbitMQBtnEvent(
    //   { identity: identity, product_id: product_id, platform_id: platform_id },
    //   "Btn_Back_PG"
    // );
    // if (platform_id === "1") {
      // updateOrderStatus(identity)
      //   .then((res) => {
      //     if (res?.status === "success") {
      //       console.log("upateOrderRes::", res);
      //     } else if (res.status === "failed") {
      //       // console.log("upateOrder Failed Result::", res);
      //     }
      //   })
      //   .catch((err) => {
      //     console.log("upateOrderRes ERR::", err);
      //   });
      // window.location.href = `${process.env.REACT_APP_HANGAMA_URL}/wvclose.php`;
    // }
    // if (plan_type !== "subscription" && contentId !== "" && planName !== "") {
    //   const contentName = planName?.replace(/\s+/g, "-");
    //   window.location.href =
    //     `${process.env.REACT_APP_HUNGAMA_WEB_URL}/movie/` +
    //     contentName +
    //     "/" +
    //     contentId;
    // }
    // history.push(
    //     '/plan?' + urlparams,
    // );
    console.log('platform_id',platform_id,'isAndroid',isAndroid);
    if (platform_id === "1" && isAndroid) {
      window.location.href = `${process.env.REACT_APP_HANGAMA_URL}/wvclose.php`;
    }
    history.goBack();
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    let data = { ...formData };
    data[name] = value;
    setFormData(data);
  };

  //hiding apply coupon flow due to google policy
  /*
  const handleCoupenClick = (e) => {
    //amplitudeBtnEvent(identity, "Btn_Open_Coupon", product_id, platform_id);
    ampRabbitMQBtnEvent({identity:identity,product_id:product_id,platform_id:platform_id},"Btn_Open_Coupon")
    const name = e?.target?.getAttribute("data-name");
    let value = e?.target?.getAttribute("value");
    setPushbar((prev) => {
      // console.log(value);
      return { ...prev, [name]: value === "true" ? true : false }; //value === "true" ? true : false
    });

    ampRabbitMQPopUpEvent({
        identity:identity,
        Source: utm_source,
        "Plan Name": plan?.plan_name,
        "Plan Validity": plan?.plan_valid,
        "Plan Value": plan?.plan_price?.toFixed(2),
        "Plan Currency": plan?.plan_currency,
        "Plan ID": plan_id,
        "Affiliate ID": aff_id,
        "product_id":product_id,
        "platform_id":platform_id,
        Action: "Coupon_Code_Box",
      })

  };
  */

  const handleClearCoupon = () => {
    setFormData((prev) => {
      return { ...prev, couponcode: "" };
    });
    setFormData((prev) => {
      return {
        ...prev,
        couponError: false,
      };
    });
  };

  const cancelVerifyCoupon = (e) => {
    if (e) e.preventDefault();
    setFormData((prev) => {
      return {
        ...prev,
        couponcode: "",
        couponVerified: false,
        freeCoupon: false,
      };
    });
    setPushbar((prev) => {
      return { ...prev, openCoupon: false };
    });
    setPlan((prev) => {
      return { ...prev, discountPrice: undefined };
    });
    setIsCouponValid(false);
  };

  if (
    couponcode === null ||
    (couponcode && couponcode.trim() === "") ||
    couponcode === undefined
  ) {
    formData.couponVerified = false;
  }

  const verifyCoupon = (e) => {
    // amplitudeBtnEvent(identity, "Btn_Submit_Coupon", product_id, platform_id);
    ampRabbitMQBtnEvent(
      {
        identity: identity,
        product_id: product_id,
        platform_id: platform_id,
      },
      "Btn_Submit_Coupon"
    );
    // gaEventTracker('apply','HUNGAMA_'+`${formData.couponcode}`);
    gaEventTracker("apply", `HUNGAMA_${formData?.couponcode}`);
    if (e) e.preventDefault();
    const url = `${process.env.REACT_APP_HANGAMA_URL}/coupons/get_discounted_value.php?coupon_code=${formData?.couponcode}&${urlparams}`;
    axios
      .get(url)
      .then((res) => {
        setisLoadingVerify(false);
        const couponDiscount = get(res, "data.coupon_details.value", "");
        const coupontype = get(res, "data.coupon_details.type", "");
        const couponStatus = get(res, "data.status", "");
        const plansApplicable = get(
          res,
          "data.coupon_details.plansApplicable",
          ""
        );

        if (couponStatus === 1) {
          setIsCouponValid(true);
        }

        if (couponDiscount !== "" && couponStatus !== -1) {
          let discount;
          if (
            coupontype === "discounted" &&
            plansApplicable.includes(plan_id)
          ) {
            discount = Math.round(
              Number(plan?.plan_price) -
                (Number(couponDiscount) / 100) * Number(plan?.plan_price)
            );
            if (discount > 0) {
              setFormData((prev) => {
                return { ...prev, couponVerified: true, couponError: false };
              });
              setPushbar((prev) => {
                return { ...prev, openCoupon: false };
              });
              setPlan((prev) => {
                return { ...prev, discountPrice: discount };
              });
            }
          } else if (
            coupontype === "flat" &&
            plansApplicable.includes(plan_id)
          ) {
            discount = Math.round(
              Number(plan?.plan_price) - Number(couponDiscount)
            );
            if (discount > 0) {
              setFormData((prev) => {
                return { ...prev, couponVerified: true, couponError: false };
              });
              setPushbar((prev) => {
                return { ...prev, openCoupon: false };
              });
              setPlan((prev) => {
                return { ...prev, discountPrice: discount };
              });
            }
          } else if (
            coupontype === "free" &&
            plansApplicable.includes(plan_id)
          ) {
            discount = Number(couponDiscount).toFixed();
            if (discount >= 0) {
              setFormData((prev) => {
                return {
                  ...prev,
                  couponVerified: true,
                  couponError: false,
                  freeCoupon: true,
                };
              });
              //set freeCouponData
              setFreeCouponData(res?.data);
              setPushbar((prev) => {
                return { ...prev, openCoupon: false };
              });
              setPlan((prev) => {
                return { ...prev, discountPrice: discount };
              });
            }
          } else {
            //counpon not valid for this plan
            setFormData((prev) => {
              return {
                ...prev,
                couponVerified: false,
                couponError: true,
                CouponNotApplicableForPlan: true,
              };
            });
          }
          // console.log("discount", discount);
          //MINUS handel
          // discount = discount > 0 ? discount : undefined;
        } else if (couponStatus === 0 || couponStatus === "0") {
          setFormData((prev) => {
            return {
              ...prev,
              couponVerified: false,
              couponError: true,
              isCouponExpired: false,
              CouponNotApplicableForPlan: false,
            };
          });
        } else {
          setFormData((prev) => {
            return {
              ...prev,
              couponVerified: false,
              couponError: true,
              isCouponExpired: true,
            };
          });
          // setPlan((prev) => {
          //   return { ...prev, discountPrice: undefined };
          // });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //BuyGoldOnFreeCoupon
  const buyGoldOnFreeCoupon = () => {
    // console.log("BUY GOLD ON FREE COUPON");
    if (
      freeCouponData?.status === 1 &&
      freeCouponData?.coupon_details?.type === "free"
    ) {
      let obj = {
        couponCode: freeCouponData?.coupon_code,
        status: freeCouponData?.status,
        ...freeCouponData?.coupon_details,
      };
      let { couponCode, plan_details_id, plan_id } = obj;
      return (window.location.href = `${
        process.env.REACT_APP_HANGAMA_URL
      }/billing/pay.php?payment_id=13&plan_details_id=${plan_details_id}&type=charge&plan_id=${plan_id}&coupon_code=${couponCode}&${currenturl.replace(
        "?",
        ""
      )}`);
    }
  };
  // console.log(couponcode);
  if (isLoadingVerify) {
    if (couponcode !== "") {
      verifyCoupon();
    }
  }

  useEffect(() => {
    //free trial
    if (freeTrialPlanData?.isActive === true) {
      checkFreeTrialStatus({ product_id, country, identity }).then((res) => {
        if (res?.status === SUCCESS) {
          // console.log("freetrial success!", res);
          if (
            res?.data?.free_trial_status === false &&
            freeTrialPlanData?.country === country &&
            freeTrialPlanData?.product_id === product_id
          ) {
            let obj = { ...freeTrialPlanData, status: false };
            setFreeTrialData(obj);
          } else {
            let obj = { ...freeTrialPlanData, status: true };
            setFreeTrialData(obj);
          }
        }
      });
    } else {
      let obj = { ...freeTrialPlanData, status: true };
      setFreeTrialData(obj);
    }
  }, [country, identity, product_id]);

  useEffect(() => {
    if (
      isLoading &&
      isFreeCoupon === false &&
      Object.keys(freeTrialData)?.length > 0
    ) {
      language(lan).then((res) => {
        //set conditionally
        setTextData(res["secondLang"] ? res["secondLang"] : res["default"]);
      });

      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        "api-key": process.env.REACT_APP_API_KEY,
      };

      const body = {
        product_id: product_id,
        platform_id: platform_id,
        country: country,
        plan_id: plan_id,
        plan_type: plan_type,
        content_id: content_id,
        identity: identity,
      };

      axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/v1/billing/payments`,
        headers: headers,
        data: body,
      })
        .then((res) => {
          //Free Trial Payment Page Code
          if (
            Number(freeTrialData?.plan_id) === Number(plan_id) &&
            freeTrialData?.status === false
          ) {
            //  console.log('freetrial set:::',obj)
            // console.log("freetrial success payment page mob");
            res.data.planInfo.plan_price = Number(freeTrialData?.plan_amt);
            res.data.payments = res?.data?.payments.filter(
              (el) =>
                el?.plan_group_type === "CC" ||
                el?.plan_group_type === "upiPayments"
            );
            // console.log(
            //   "updated payments::",
            //   res?.data?.payments,
            //   "arr",
            //   res?.data?.payments.filter(
            //     (el) =>
            //       el?.plan_group_type === "CC" ||
            //       el?.plan_group_type === "upiPayments"
            //   )
            // );
            //remive coupon on trial offer
            setFormData((prev) => ({
              couponcode: "",
            }));
            //set default open UPI panel
            setTabHideShow((prevState) => ({
              ...prevState,
              upiTab: true,
              ccTab: false,
            }));
          }
          const featuredData = res?.data?.featuredPayment;
          const planData = res?.data?.planInfo;
          const paymentsdata = res?.data?.payments;
          const walletPaymentsdata = res?.data?.walletPayments.filter(function(el) { return el.payment_name != "Paytm"; });
          const upiPaymentsdata = res?.data?.upiPayments;
          const netBankingPaymentsdata = res?.data?.netBankingPayments;
          // console.log(paymentsdata);
          // console.log("payments.total_user_coins");
          // console.log();
          //var item_value = sessionStorage.getItem("plan_name_val");

          // Assign value to a key
          sessionStorage.setItem(
            "plan_name_val",
            planData?.plan_name + "_" + planData?.plan_price
          );
          setTotal_user_coins(res?.data?.total_user_coins);
          setFeatured(featuredData);
          setPlan(planData);
          setPayments(paymentsdata);
          setWallets(walletPaymentsdata);
          setNetBankingPayments(netBankingPaymentsdata);
          setUpiPayments(upiPaymentsdata);
          setLoading(false);
          setisLoadingVerify(true);

          /* amplitude code start*/
          let pageType = "",
            source = "";
          if (plan_type === "subscription" || plan_type === "event") {
            pageType = "PG Page_Subscription";
            source = "Plan Page";
          } else if (plan_type === "live_concert") {
            pageType = "PG Page_Live Event";
            source = "N/A";
          } else {
            pageType = "PG Page_TVOD";
            source = "N/A";
          }
          /*
          amplitudePageEvent(
            identity,
            {
              "Page Type": pageType,
              Source: source,
              "Original Source": originalSource ? originalSource : "N/A",
              "Plan Name": planData?.plan_name,
              "Plan Validity": planData?.plan_valid,
              "Plan Value": planData?.plan_price?.toFixed(2),
              "Plan Currency": planData?.plan_currency,
              "Plan ID": plan_id,
              "Page Language": lan ? lan : "en",
            },
            product_id,
            platform_id
          );
          */
          /* amplitude with RabbitMQ*/
          ampRabbitMQPageEvent({
            identity: identity ? identity : "",
            "Page Type": pageType,
            Source: source,
            "Original Source": originalSource ? originalSource : "N/A",
            "Plan Name": planData?.plan_name,
            "Plan Validity": planData?.plan_valid,
            "Plan Value": planData?.plan_price?.toFixed(2),
            "Plan Currency": planData?.plan_currency,
            "Plan ID": plan_id ? plan_id : "",
            "Page Language": lan ? lan : "en",
            product_id: product_id ? product_id : "",
            platform_id: platform_id ? platform_id : "",
            device : "mobile"
          });
        })
        .catch((err) => {
          // console.log(err);
          setLoading(false);
        });
      const logData = {
        url: `${hostname}${pathname}`,
        params: location,
        couponcode: couponcode,
        plan_id: plan_id,
        product_id: product_id,
        platform_id: platform_id,
        country: country,
        identity: identity,
        content_id: content_id,
        plan_type: plan_type,
        upiList: uL,
      };

      const data = JSON.stringify(logData);
      const body1 = {
        logData: data,
      };

      axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/v1/frontendlogs`,
        headers: headers,
        data: body1,
      })
        .then((res) => {})
        .catch((err) => {
          // console.log(err);
        });

      // var requestOptions = {
      //   method: "GET",
      //   redirect: "follow",
      // };

      // fetch("https://ipapi.co/json/", requestOptions)
      //   .then((response) => response.text())
      //   .then((res) => {
          // const result = JSON.parse(res);
          // // return '';
          // let campaignsourceval, affilaite;
          // if (!npay_campaignsource) { campaignsourceval = campaignsource; }
          // if (!npay_affilaite) { affilaite = aff_id; }
          // /* Rabbit MQ */
          // const rabbitMQData = {
          //     'identity': identity,
          //     'product_id': product_id,
          //     'auth-key': process.env.REACT_APP_MQ_AUTH_KEY,
          //     'event_type': 'npay_page_paymentoptions',
          //     'event_time': Math.floor(new Date().getTime() / 1000),
          //     'queue_name': process.env.REACT_APP_QUEUE_NAME,
          //     'ip': result.ip,
          //     'country': result.country,
          //     'event_properties': {
          //         'npay_redirect': 'false',
          //         'campaignsource': campaignsourceval,
          //         'affilaite': affilaite,
          //     }
          // }
          // console.log(rabbitMQData);
          // axios({
          //     method: "POST",
          //     url: `${process.env.REACT_APP_API_URL}/v1/notify/mq/receiver`,
          //     headers: headers,
          //     data: rabbitMQData
          // })
          //     .then((res) => {
          //         // console.log('rabbitmq')
          //         // console.log(res);
          //     })
          //     .catch((err) => {
          //         console.log(err);
          //     });
        // })
        // .catch((error) => console.log("error", error));
    }
  }, [
    utm_source,
    originalSource,
    aff_id,
    npay_redirect,
    aff_id,
    campaignsource,
    npay_affilaite,
    npay_campaignsource,
    pushbar.openCoupon,
    country,
    couponcode,
    hostname,
    identity,
    location,
    pathname,
    uL,
    isLoading,
    plan_id,
    urlparams,
    content_id,
    plan_type,
    platform_id,
    product_id,
    lan,
    freeTrialData,
    isFreeCoupon,
  ]);

  const time = new Date();
  time.setSeconds(time.getSeconds() + 300); // 10 minutes timer

  // console.log(upiPayments);
  useEffect(() => {
    if (
      pushbar.openCoupon ||
      pushbar.Verifybottom1 ||
      pushbar.Verifybottom2 ||
      pushbar.verifyRedeemCoins ||
      pushbar.verifyAmazonPay
    ) {
      document.body.style.overflow = "hidden";
      document.body.style.position = "fixed"; //ios
      document.body.style.height = "100vh"; //ios
      document.body.style.width = "100%"; //ios
    } else if (!pushbar.openCoupon) {
      document.body.style.overflow = "visible";
      document.body.style.removeProperty("position"); //ios
      document.body.style.removeProperty("height"); //ios
      document.body.style.removeProperty("width"); //ios
    }
  }, [pushbar]);
  return (
    <>
      {isLoading ? (
        <div className="loading">Loading...</div>
      ) : (
        <div>
          <div
            className="wrapper"
            style={{
              pointerEvents:
                pushbar?.openCoupon ||
                pushbar?.Verifybottom1 ||
                pushbar?.Verifybottom2 ||
                pushbar?.verifyRedeemCoins ||
                pushbar?.verifyAmazonPay
                  ? "none"
                  : "auto",
            }}
          >
            <div className="main-container">
              <div className="pay-head">
                <div
                  className="pay-back"
                  // onClick={() => {goToPreviousPath(content_id, plan?.plan_name)}}
                >
                  <img
                    src={
                      process.env.REACT_APP_ASSETS_URL +
                      "/mobile/images/back-arrow.svg"
                    }
                    // onClick={
                    //   (e) => e.preventDefault()
                    //   // goToPreviousPath(content_id, plan?.plan_name)
                    // }
                    onClick={() => {goToPreviousPath(content_id, plan?.plan_name)}}
                    alt="arrow"
                  />
                </div>
                <span>{textData?.pay_payment_heading}</span>
              </div>
              <div className="plan-box">
                <div className="plan-box-top">
                  <div className="plan-box-inner flex2">
                    <div className="plan-poster-box">
                      {plan?.plan_image !== "" && (
                        <img
                          src={plan?.plan_image}
                          alt="poster"
                          className="plan-posterimg"
                        />
                      )}
                    </div>
                    <div className="plan-content-box">
                      <div className="plan-dname">
                        {plan?.plan_dname !== "" &&
                        plan?.plan_name === "VALUE PACK"
                          ? "Ads Free"
                          : plan?.plan_dname}
                      </div>
                      <h3 className="plan-name">
                        {plan?.plan_name !== "" && plan?.plan_name}
                      </h3>
                      <p className="plan-des">
                        {plan?.plan_dname === "live event"
                          ? plan?.event_date
                          : plan?.plan_des}{" "}
                        {plan?.plan_dname === "live event" && (
                          <span className="event-time">
                            {plan?.event_time !== "" && plan?.event_time}
                          </span>
                        )}
                      </p>
                      <p className="plan-valid">
                        {plan?.plan_valid !== "" && plan?.plan_valid}
                      </p>
                    </div>
                    <div className="plan-price-box">
                      {plan?.discountPrice ? (
                        <p className="plan-price ruppetxt">
                          {plan?.plan_currency_symbol}
                          {plan.discountPrice}
                          <br />
                          <span className="rwpee-icon">
                            {plan?.plan_currency_symbol}
                            {plan.plan_price}
                          </span>
                        </p>
                      ) : (
                        <p className="plan-price">
                          {plan?.plan_currency_symbol}
                          {plan?.plan_price}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                {formData.couponVerified ? (
                  <div className="plan-coupon-new">
                    {/* {console.log("cpeenn" + " " + formData.couponVerified)} */}
                    <div className="coupon-code-new">
                      <span>
                        {formData.couponcode}
                        {textData?.pay_coupon_applied_text}
                        {plan?.plan_currency_symbol}
                        {plan?.plan_price - plan.discountPrice}{" "}
                        {textData?.pay_coupon_off_text}
                      </span>
                      <span
                        onClick={(e) => cancelVerifyCoupon(e)}
                        className="crossicn"
                        style={{ marginLeft: "10px" }}
                      ></span>
                    </div>
                  </div>
                ) : (
                  ""
                  // hiding coupon flow on payment page due to Google Pay Policy
                  /*
                  <div className="plan-coupon">
                    <div
                      className="coupon-code pointer"
                      data-name="openCoupon"
                      value={true}
                      style={{
                        pointerEvents:
                          freeTrialData?.status === false &&
                          freeTrialData?.plan_id === plan_id
                            ? "none"
                            : "auto",
                      }}
                      onClick={(e) => handleCoupenClick(e)}
                    >
                      {textData?.pay_coupon_placeholder_text}
                    </div>
                  </div>
                  */
                )}
              </div>
              <div
                className={`pay-optinbox ${
                  formData?.freeCoupon === true ? "disable-bg" : ""
                }`}
              >
                <div className="pay-optinbox-inner">
                  <Offers
                    featured={featured}
                    urlparams={urlparams}
                    textData={textData}
                    coupon_code={isCouponValid ? formData.couponcode : ""}
                  />
                  {/* objs.sort((a, b) => a.last_nom.localeCompare(b.last_nom)); */}
                  <div style={{ marginTop: "15px", textAlign: "left", position: "relative" }}>
<div style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}>
    {/* Helper Text */}
    <p
        style={{
          fontSize: "3.2vw",
          color: "#aaa",
          marginTop: "5px",
          fontWeight: "400"
        }}
      >
        *Please select your current state as required by government guidelines
      </p>
  </div>

      {/* Custom Dropdown */}
      <div
        onClick={() => setIsOpen(!isOpen)}
        style={{
          width: "100%",
          backgroundColor: "#333",
          borderRadius: "8px",
          padding: "10px",
          color: "#fff",
          fontSize: "14px",
          cursor: "pointer",
          position: "relative",
          border: "1px solid #444",
        }}
      >
        {selectedState}
        <span
          style={{
            position: "absolute",
            right: "10px",
            top: "50%",
            transform: "translateY(-50%)",
            pointerEvents: "none",
            color: "#bbb",
          }}
        >
          ▼
        </span>
      </div>

      {/* Dropdown Options */}
      {isOpen && (
        <ul
          ref={dropdownRef}
          style={{
            listStyle: "none",
            padding: "0",
            margin: "5px 0 0",
            backgroundColor: "#222",
            borderRadius: "8px",
            border: "1px solid #444",
            position: "absolute",
            width: "100%",
            zIndex: 100,
            maxHeight: "180px", // Limit height to avoid overflow
            overflowY: "auto", // Enable scrolling
            bottom: dropdownPosition === "top" ? "100%" : "auto",
            top: dropdownPosition === "bottom" ? "100%" : "auto",
          }}
        >
          {states.map((state, index) => (
            <li
              key={index}
              onClick={() => {
                setSelectedState(state);
                setIsOpen(false);
              }}
              style={{
                padding: "10px",
                cursor: "pointer",
                color: "#fff",
                fontSize: "14px",
                transition: "background 0.3s",
              }}
              onMouseOver={(e) => (e.target.style.background = "#444")}
              onMouseOut={(e) => (e.target.style.background = "transparent")}
            >
              {state}
            </li>
          ))}
        </ul>
      )}
    </div>
    <br />{textData?.pay_payment_method_label}
                  {payments
                    .sort(
                      (a, b) =>
                        parseFloat(a.display_order) -
                        parseFloat(b.display_order)
                    )
                    .map((data, i) => (
                      <div key={i}>
                        {/*Free Trial*/}
                        {data.plan_group_type === "upiPayments" && (
                          <Upipayments
                            tabHideShow={tabHideShow}
                            setTabHideShow={setTabHideShow}
                            pushbar={pushbar}
                            setPushbar={setPushbar}
                            plan_id={plan_id}
                            aff_id={aff_id}
                            utm_source={utm_source}
                            planData={plan}
                            uL={newUpiList}
                            identity={identity}
                            product_id={product_id}
                            platform_id={platform_id}
                            upiPayments={upiPayments}
                            urlparams={urlparams}
                            textData={textData}
                            coupon_code={
                              isCouponValid ? formData.couponcode : ""
                            }
                            appVersion={appVersion}
                            buildNumber={buildNumber}
                            isFreeTrial={freeTrialData}
                            jusPayUser={jusPayIdentity}
                            state={selectedState}
                          />
                        )}
                        {data.plan_group_type === "netBankingPayments" && (
                          <Netbanking
                            identity={identity}
                            planData={plan}
                            netBankingPayments={netBankingPayments}
                            urlparams={urlparams}
                            platform_id={platform_id}
                            product_id={product_id}
                            textData={textData}
                            lang={lan}
                            coupon_code={
                              isCouponValid ? formData.couponcode : ""
                            }
                            jusPayUser={jusPayIdentity}
                            state={selectedState}
                          />
                        )}
                        {data.plan_group_type === "CC" && (
                          <Payments
                            tabHideShow={tabHideShow}
                            setTabHideShow={setTabHideShow}
                            pushbar={pushbar}
                            setPushbar={setPushbar}
                            plan_id={plan_id}
                            aff_id={aff_id}
                            utm_source={utm_source}
                            planData={plan}
                            identity={identity}
                            product_id={product_id}
                            platform_id={platform_id}
                            payments={payments}
                            urlparams={urlparams}
                            textData={textData}
                            total_user_coins={total_user_coins}
                            coupon_code={
                              isCouponValid ? formData.couponcode : ""
                            }
                            isFreeTrial={freeTrialData}
                            state={selectedState}
                          />
                        )}
                        {data.plan_group_type === "walletPayments" && (
                          <Wallets
                            pushbar={pushbar}
                            setPushbar={setPushbar}
                            identity={identity}
                            wallets={wallets}
                            planData={plan}
                            urlparams={urlparams}
                            product_id={product_id}
                            platform_id={platform_id}
                            textData={textData}
                            coupon_code={
                              isCouponValid ? formData.couponcode : ""
                            }
                            jusPayUser={jusPayIdentity}
                            state={selectedState}
                          />
                        )}
                      </div>
                    ))}
                  {/*Free Trial*/}
                  {freeTrialData?.status === false &&
                    freeTrialData?.plan_id === plan_id &&
                    freeTrialData?.freeTrialPaymentPageText?.map((data, i) => (
                      <div
                        style={{
                          marginTop: "14px",
                          marginLeft: "8px",
                          fontSize: "12px",
                          fontWeight: "500",
                        }}
                      >
                        <p key={i}>{data}</p>
                      </div>
                    ))}
                </div>
              </div>
              {formData?.freeCoupon === true && (
                <div className="btngold-freecoupon">
                  <div
                    onClick={(e) => buyGoldOnFreeCoupon()}
                    className="pointer"
                  >
                    <img
                      src={
                        process.env.REACT_APP_ASSETS_URL +
                        "/mobile/images/goldimg.svg"
                      }
                      alt="gold"
                    />{" "}
                    {textData?.pay_buyplan_btn_text}
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* hinding apply coupon flow, popup due to google policy */}
          {/* <aside
            data-pushbar-id="bottom"
            ref={ref}
            className={
              pushbar.openCoupon
                ? "pushbar from_bottom opened"
                : "pushbar from_bottom"
            }
          >
            <div class="paddcpbox">
              <div
                class="coupon-close"
                onClick={(e) =>
                  setPushbar((prev) => {
                    return { ...pushbar, openCoupon: false };
                  })
                }
                data-pushbar-close
              >
                <img
                  alt="close"
                  src={
                    process.env.REACT_APP_ASSETS_URL +
                    "/mobile/images/close-btn.svg"
                  }
                />
              </div>
              <div class="close_sep"></div>
              <div className="cptitle">{textData?.pay_coupon_popup_text}</div>
              <div class="cpbxcon">
                <div class="cpbxcon-input-box">
                  <input
                    autoComplete="off"
                    class={formData.couponError && "error-border"}
                    type="text"
                    name="couponcode"
                    value={formData.couponcode}
                    onChange={(e) => handleInput(e)}
                  />
                  {formData.couponError && (
                    <img
                      alt="close icon"
                      onClick={(e) => handleClearCoupon(e)}
                      src={
                        process.env.REACT_APP_ASSETS_URL +
                        "/mobile/images/error-close.svg"
                      }
                      class="error-closebtn"
                    />
                  )}
                </div>
                <a
                  href="!#"
                  class="button-blue"
                  onClick={(e) => verifyCoupon(e)}
                >
                  {textData?.pay_coupon_apply_text}
                </a>
              </div>
              {formData.couponError &&
                !formData.CouponNotApplicableForPlan &&
                !formData.isCouponExpired && (
                  <div class="error-box">
                    {textData?.pay_coupon_invalid_text}
                  </div>
                )}
              {formData.couponError &&
                formData.CouponNotApplicableForPlan === true && (
                  <div class="error-box">
                    {textData?.pay_coupon_not_applicable_for_plan}
                  </div>
                )}
              {formData.couponError && formData.isCouponExpired === true && (
                <div class="error-box">{textData?.pay_coupon_expired_text}</div>
              )}
            </div>
          </aside> */}
        </div>
      )}
    </>
  );
};
