import React, { useState, useEffect } from "react";
import ReactGA4 from "react-ga4";
import ReactGA from "react-ga";
// import slide1 from "../img/slide1.png";
import axios from "axios";
import { useLocation, useHistory } from "react-router-dom";
import get from "lodash.get";
import Upipayments from "../upi/intent_juspay";
// import { backendApiUrl } from "./utils";
import {
  amplitudeBtnEvent,
  ampRabbitMQBtnEvent,
  amplitudePageEvent,
  ampRabbitMQPageEvent,
  amplitudeCustomEvent,
  ampRabbitMQCustomEvent,
  langConvrt,
} from "../../utils";

import { language, checkFreeTrialStatus } from "../../utils/services";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
import { freeTrialPlanData, SUCCESS } from "../../../constants";
// import  { install } from 'ga-gtag';
import { getJusPayCustomer, get_jus_pay_identity } from "../../utils/juspay";
import { payments as paymentsPlanInfoDetails} from "../../utils/payments";
SwiperCore.use([Navigation, Pagination, Autoplay]);

export function Plan() {
  // const ref = useRef();

  let currenturl = useLocation().search;
  const couponcode = new URLSearchParams(currenturl).get("couponcode");
  const plan_id = new URLSearchParams(currenturl).get("plan_id");
  const product_id = new URLSearchParams(currenturl).get("product_id");
  const platform_id = new URLSearchParams(currenturl).get("platform_id");
  const country = new URLSearchParams(currenturl).get("country");
  const identity = new URLSearchParams(currenturl).get("identity");
  const plan_type = new URLSearchParams(currenturl).get("plan_type");
  const hardware_id = new URLSearchParams(currenturl).get("hardware_id");
  const content_id = new URLSearchParams(currenturl).get("content_id");
  const lan = new URLSearchParams(currenturl).get("lang");
  const uL = new URLSearchParams(currenturl).get("upilist");
  const website = new URLSearchParams(currenturl).get("website");
  const app = new URLSearchParams(currenturl).get("app");
  const state = new URLSearchParams(currenturl).get("state");
  const npay_redirect = new URLSearchParams(currenturl).get("npay_redirect");
  const npay_campaignsource = new URLSearchParams(currenturl).get(
    "npay_campaignsource"
  );
  const [featured, setFeatured] = useState({});
  const [payments, setPayments] = useState([]);
  const [wallets, setWallets] = useState([]);
  const [newUpiList,setNewUpiList] = useState()
    const [isFreeCoupon, setIsFreeCoupon] = useState(false);
  const [netBankingPayments, setNetBankingPayments] = useState([]);
    const [total_user_coins, setTotal_user_coins] = useState([]);
    const [isLoadingVerify, setisLoadingVerify] = useState(false);
    const originalSource = new URLSearchParams(currenturl).get("source");
    const appVersion = new URLSearchParams(currenturl).get("app_version");
    const buildNumber = new URLSearchParams(currenturl).get("build_number")
  const campaignsource = new URLSearchParams(currenturl).get("campaignsource");
  const npay_affilaite = new URLSearchParams(currenturl).get("npay_affilaite");
  const aff_id = new URLSearchParams(currenturl).get("aff_id");
  const utm_source = new URLSearchParams(currenturl).get("utm_source");
  const source = new URLSearchParams(currenturl).get("source");
  const TRACKING_ID = `${process.env.REACT_APP_GA_CODE_PWA}`; // OUR_TRACKING_ID
  const GA4_TRACKING_ID = `${process.env.REACT_APP_GA4_CODE}`; // OUR_GA4_TRACKING_ID
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
   const [ContenDescription, setContenDescription] = useState('test popup');
   const [upiPayments, setUpiPayments] = useState([]);
     const [plan, setPlan] = useState({ discountPrice: " " });
       const [isCouponValid, setIsCouponValid] = useState(false);
     const [tabHideShow, setTabHideShow] = useState({
       upiTab: true,
       ccTab: false,
     });
      const [pushbar, setPushbar] = useState({
         openCoupon: false,
         Verifybottom1: false,
         Verifybottom2: false,
         verifyRedeemCoins: false,
         verifyAmazonPay: false,
         isTimer: false,
       });
  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    ReactGA.pageview(window.location.pathname);
  }, [TRACKING_ID]);

 useEffect(() => {
    setNewUpiList(uL)
  if(website == 'true'){
    setNewUpiList("phonepe,gpay,bhim,paytm")
    console.log('newUpiList',newUpiList);
  }
}, [])

  useEffect(() => {
    if (website) {
      localStorage.setItem("website", website);
   }
    if (website === null) {
      localStorage.setItem('website', 'false');
    }
 }, []);

  const useAnalyticsEventTracker = (category = "Payment Init") => {
    const eventTracker = (action = "action init", label = "label init") => {
      ReactGA.event({ category, action, label });
    };
    return eventTracker;
  };

  const urlparams = currenturl.replace("?", "");
  const [jusPayIdentity, setjusPayIdentity] = useState({});
  useEffect( async () => {
    const loader = document?.getElementById('loader');
    if (loader) {
      loader.style.display = 'none';
    }
    const getJusPayUser = await get_jus_pay_identity(identity);
    if(getJusPayUser.message_code == 'GET_CUSTOMER_SUCCESS'){
        jusPayIdentity.juspay_identity = getJusPayUser.juspay_identity;
        jusPayIdentity.hungama_identity = getJusPayUser.identity;
        console.log("in credit page getUSER => ", getJusPayUser); 
        setjusPayIdentity({...jusPayIdentity});
        localStorage.setItem('juspay_user',JSON.stringify(jusPayIdentity));
    }
    else{
      const createJusPayUser = await getJusPayCustomer(identity);
      jusPayIdentity.juspay_identity = createJusPayUser.juspay_identity;
      jusPayIdentity.hungama_identity = createJusPayUser.identity;
      setjusPayIdentity({...jusPayIdentity});
      console.log("in credit page createUSER => ", createJusPayUser); 
      localStorage.setItem('juspay_user',JSON.stringify(jusPayIdentity));
    }
  }, []);
  // const textData1 =

  const hostname = window.location.host;
  const pathname = useLocation().pathname;
  var ua = navigator.userAgent.toLowerCase();
  var isAndroid = ua.indexOf("android") > -1; //&& ua.indexOf("mobile");
  let history = useHistory();
  console.log('isAndroid',isAndroid)
  const goToPreviousPath = () => {
    //amplitudeBtnEvent(identity, "Btn_Back_Plan", product_id, platform_id);
    ampRabbitMQBtnEvent(
      {
        identity: identity,
        product_id: product_id,
        platform_id: platform_id,
      },
      "Btn_Back_Plan"
    );
    if (platform_id === "1" && isAndroid) {
      window.location.href = `${process.env.REACT_APP_HANGAMA_URL}/wvclose.php`;
    }
    if(website == 'true' && app == 'hungama'){
      window.location.href = `${process.env.REACT_APP_HUNGAMA_WEB_URL}`;
    }
    if(website == 'true' && app == 'tamasha'){
      window.location.href = `${process.env.REACT_APP_TAMASHA_WEB_URL}`;
    }
    if(website == 'true' && app == 'mymuze'){
      window.location.href = `${process.env.REACT_APP_VODACOM_WEB_URL}`;
    }
    history.goBack();
  };

  // console.log(currenturl);
  const [openCoupon, setOpenCoupon] = useState(false);

  const [formData, setFormData] = useState({
    couponError: false,
    plan_id: "",
    couponcode: "",
    currenturl: currenturl,
    couponStatus: 0,
  });
  const [freeCouponData, setFreeCouponData] = useState({});

  // const [couponApplied, setCouponApplied] = useState(true);
  const [applyBtn, setApplyBtn] = useState(false);
  const [showApplyBtn, setShowApplyBtn] = useState(true);

  const [plans, setPlans] = useState([]);
  const [oldPlans, setOldPlans] = useState([]);

  // const [sliders, setSlider] = useState([]);
  const [isLoadingOnce, setLoadingOnce] = useState(true);
  const [isLoading, setLoading] = useState(true);
  const [textData, setTextData] = useState({});
  const [defaultTextData, setDefaultTextData] = useState({});
  const [freeTrialData, setFreeTrialData] = useState({});


  const handleCouponCancel = (identity) => {
    // amplitudeBtnEvent(identity, "Btn_Remove_Coupon", product_id, platform_id);
    ampRabbitMQBtnEvent(
      {
        identity: identity,
        product_id: product_id,
        platform_id: platform_id,
      },
      "Btn_Remove_Coupon"
    );
   
  };



  useEffect(() => {
    if (isLoadingOnce) {
     
      ampRabbitMQPageEvent({
        identity: identity ? identity : "",
        "Page Type": "Plan Page",
        Source: source ? source : "N/A",
        "Affiliate ID": aff_id ? aff_id : "",
        "Page Language": lan ? lan : "en",
        product_id: product_id ? product_id : "",
        platform_id: platform_id ? platform_id : "",
      });

      // const logData = {
      //   url: hostname + pathname,
      //   params: currenturl,
      //   couponcode: couponcode,
      //   plan_id: plan_id,
      //   product_id: product_id,
      //   platform_id: platform_id,
      //   country: country,
      //   identity: identity,
      //   plan_type: plan_type,
      //   hardware_id: hardware_id,
      // };

      // const headers = {
      //   Accept: "application/json",
      //   "Content-Type": "application/json;charset=UTF-8",
      //   "api-key": process.env.REACT_APP_API_KEY,
      // };

      // const data = JSON.stringify(logData);
      // const body = {
      //   logData: data,
      // };

      // axios({
      //   method: "POST",
      //   url: `${process.env.REACT_APP_API_URL}/v1/frontendlogs`,
      //   headers: headers,
      //   data: body,
      // })
      //   .then((res) => {
      //     // console.log(res);
      //   })
      //   .catch((err) => {
      //     // console.log(err);
      //   });

      language(lan).then((res) => {
        //set Lang conditionally
        // setTextData(res);
        setDefaultTextData(res?.default);
        setTextData(res?.secondLang ? res["secondLang"] : res["default"]);
        // console.log(res);
      });
    }
    setLoadingOnce(false);
  }, [
    isLoading,
    hostname,
    pathname,
    currenturl,
    couponcode,
    plan_id,
    product_id,
    isLoadingOnce,
    platform_id,
    country,
    identity,
    plan_type,
    utm_source,
    source,
    aff_id,
    hardware_id,
    lan,
  ]);


  const handleForm = (e, pdata) => {
    const { name, value } = e.target;
    if (name !== "couponcode") {
      var valarr = value.split(":");
      if (pdata && identity) {
     
        ampRabbitMQCustomEvent(
          {
            identity: identity,
            npay_currency: pdata?.plan_currency,
            npay_planid: pdata?.plan_id,
            npay_planname: pdata?.plan_name,
            npay_plan_validity:
              pdata?.duration?.duration + " " + pdata?.duration?.text,
            npay_planvalue: pdata?.plan_price,
            product_id: product_id,
            platform_id: platform_id,
          },
          "npay_click_planselect"
        );
        // console.log("onSelectData:", pdata);
      }

      gaEventTracker("pick_plan", valarr[0] + "_" + valarr[2]);
      document.getElementById("x_plan_name").value = valarr[0];
      document.getElementById("x_plan_price").value = valarr[2];

      // setFormData((prev) => {
      //     return { prev, [name]: value };
      // })
      let data = { ...formData, plan_id: pdata?.plan_id };
      data[name] = valarr[1];
      setFormData(data);
      buygold(pdata?.plan_id)
    } else if (name === "couponcode") {
      value !== "" ? setApplyBtn(true) : setApplyBtn(false);
      let data = { ...formData };
      data[name] = value;
      setFormData(data);
    }
  };

  const buygold2 = (id) => {

    ampRabbitMQBtnEvent(
      {
        identity: identity,
        product_id: product_id,
        platform_id: platform_id,
      },
      "Btn_BottomFix_Buy Now"
    );
    var plan_name_price =
      document.getElementById("x_plan_name").value +
      "_" +
      document.getElementById("x_plan_price").value;
    gaEventTracker("buy_hungama_gold", plan_name_price);
    if (id !== "") {
      console.log('==>',id)
      history.push(
        `/payment?plan_id=${id}&couponcode=${
          formData.couponcode === undefined ? "" : formData?.couponcode
        }&${currenturl.replace("?", "")}`
      );
    } else {
      alert("please select a plan");
    }
  };

  const gaEventTracker = useAnalyticsEventTracker("Payment Plan");

  const buygold = (latest_plan_id) => {
    // amplitudeBtnEvent(
    //   identity,
    //   "Btn_BottomFix_Buy Now",
    //   product_id,
    //   platform_id
    // );
    ampRabbitMQBtnEvent(
      {
        identity: identity,
        product_id: product_id,
        platform_id: platform_id,
      },
      "Btn_BottomFix_Buy Now"
    );
    var plan_name_price =
      document.getElementById("x_plan_name").value +
      "_" +
      document.getElementById("x_plan_price").value;
    gaEventTracker("buy_hungama_gold", plan_name_price);
    if (latest_plan_id !== "") {
      if (latest_plan_id === "37" || latest_plan_id === 37) {
        var searchParams = new URLSearchParams(window.location.search);
        searchParams.set("plan_type", "event");
        currenturl = searchParams.toString();
      }
      // console.log(currenturl);
      //Free Coupon
      console.log(freeCouponData);
      if (
        freeCouponData?.status === 2 &&
        freeCouponData?.coupon_details?.type === "free"
      ) {
        let obj = {
          couponCode: freeCouponData?.coupon_code,
          status: freeCouponData?.status,
          ...freeCouponData?.coupon_details,
        };
        let { couponCode, plan_details_id } = obj;
        console.log(
          "CCCC:::",
          `${
            process.env.REACT_APP_HANGAMA_URL
          }/billing/pay.php?payment_id=13&plan_details_id=${plan_details_id}&type=charge&plan_id=${
            latest_plan_id
          }&coupon_code=${couponCode}&${currenturl.replace("?", "")}`
        );
        // return (window.location.href = `${process.env.REACT_APP_HANGAMA_URL}/billing/pay.php?payment_id=13&plan_details_id=${plan_details_id}&type=charge&plan_id=${
        //   formData?.plan_id
        // }&coupon_code=${couponCode}&${currenturl.replace("?", "")}`);
      } else if (
        freeCouponData?.status === 1 &&
        freeCouponData?.coupon_details?.type === "free" &&
        freeCouponData?.coupon_details?.plansApplicable?.includes(
          `${latest_plan_id}`
        )
      ) {
        let obj = {
          couponCode: freeCouponData?.coupon_code,
          status: freeCouponData?.status,
          ...freeCouponData?.coupon_details,
        };
        let { couponCode, plan_details_id } = obj;
        // return (window.location.href = `${
        //   process.env.REACT_APP_HANGAMA_URL
        // }/billing/pay.php?payment_id=13&plan_details_id=${plan_details_id}&type=charge&plan_id=${
        //   formData?.plan_id
        // }&coupon_code=${couponCode}&${currenturl.replace("?", "")}`);
      }

      // history.push(
      //   `/payment?plan_id=${formData.plan_id}&couponcode=${
      //     formData.couponcode === undefined ? "" : formData?.couponcode
      //   }&${currenturl.replace("?", "")}`
      // );
    } else {
      alert("please select a plan");
    }
   
    //new code to get payment page details
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "api-key": process.env.REACT_APP_API_KEY,
    };

    const body = {
      product_id: product_id,
      platform_id: platform_id,
      country: country,
      plan_id: latest_plan_id ,
      plan_type: plan_type,
      content_id: content_id,
      identity: identity,
    };
    console.log('body',body)
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/v1/billing/payments`,
      headers: headers,
      data: body,
    })
      .then((res) => {
        //Free Trial Payment Page Code
        console.log('res',res)
        if (
          Number(freeTrialData?.plan_id) === Number(plan_id) &&
          freeTrialData?.status === false
        ) {
          //  console.log('freetrial set:::',obj)
          // console.log("freetrial success payment page mob");
          res.data.planInfo.plan_price = Number(freeTrialData?.plan_amt);
          res.data.payments = res?.data?.payments.filter(
            (el) =>
              el?.plan_group_type === "CC" ||
              el?.plan_group_type === "upiPayments"
          );
          //remive coupon on trial offer
          setFormData((prev) => ({
            couponcode: "",
          }));
          //set default open UPI panel
          setTabHideShow((prevState) => ({
            ...prevState,
            upiTab: true,
            ccTab: false,
          }));
        }
        const featuredData = res?.data?.featuredPayment;
        const planData = res?.data?.planInfo;
        const paymentsdata = res?.data?.payments;
        const walletPaymentsdata = res?.data?.walletPayments.filter(function(el) { return el.payment_name != "Paytm"; });
        const NewupiPaymentsdata = res?.data?.upiPayments;
        const netBankingPaymentsdata = res?.data?.netBankingPayments;
        planData.plan_id = latest_plan_id
        console.log('planData',planData);
        
        // Assign value to a key
        sessionStorage.setItem(
          "plan_name_val",
          planData?.plan_name + "_" + planData?.plan_price
        );
        setTotal_user_coins(res?.data?.total_user_coins);
        setFeatured(featuredData);
        setPlan(planData);
        setPayments(paymentsdata);
        setWallets(walletPaymentsdata);
        setNetBankingPayments(netBankingPaymentsdata);
        setUpiPayments(NewupiPaymentsdata);
        setLoading(false);
        setisLoadingVerify(true);

        /* amplitude code start*/
        let pageType = "",
          source = "";
        if (plan_type === "subscription" || plan_type === "event") {
          pageType = "PG Page_Subscription";
          source = "Plan Page";
        } else if (plan_type === "live_concert") {
          pageType = "PG Page_Live Event";
          source = "N/A";
        } else {
          pageType = "PG Page_TVOD";
          source = "N/A";
        }      
      })
      .catch((err) => {
        // console.log(err);
        setLoading(false);
      });
      openConfirmation(latest_plan_id);
  };


const planPage = () => {
  console.log('planPage',formData.plan_id);
  ampRabbitMQCustomEvent(
    {
      identity: identity,
      plan_id: formData.plan_id,
      selected_option : "more_options"
    },
    "UPI_Drawer_payment_option_click"
  );
  history.push(
        `/payment?plan_id=${formData.plan_id}&couponcode=${
          formData.couponcode === undefined ? "" : formData?.couponcode
        }&${currenturl.replace("?", "")}`
      );
}

  const handleClearCoupon = (e, identity) => {
    setShowApplyBtn(true);
    // setFormData((prev) => {
    //   return { ...prev, couponcode: "" };
    // });
    setFormData((prev) => {
      return {
        ...prev,
        couponcode: "",
        couponError: false,
        couponStatus: 0,
      };
    });
    setFreeCouponData({});
    setPlans(oldPlans);
    handleCouponCancel(identity);
    //apply btn
    setApplyBtn(false);
  };

  const verifyCoupon = (e) => {
    if (e) e.preventDefault();
    // amplitudeBtnEvent(identity, "Btn_Submit_Coupon", product_id, platform_id);
    ampRabbitMQBtnEvent(
      {
        identity: identity,
        product_id: product_id,
        platform_id: platform_id,
      },
      "Btn_Submit_Coupon"
    );
    gaEventTracker(
      "apply_coupon",
      "HUNGAMA_" + document.getElementById("couponcode").value
    );
    axios
      .get(
        `${process.env.REACT_APP_HANGAMA_URL}/coupons/get_discounted_value.php?coupon_code=${formData?.couponcode}`
      )
      .then((res) => {
        // console.log("coupon res:", res);
        const couponval = get(res, "data.coupon_details.value", "");
        const coupontype = get(res, "data.coupon_details.type", "");
        const couponStatus = get(res, "data.status", "");
        const plansApplicable = get(
          res,
          "data.coupon_details.plansApplicable",
          ""
        );

        console.log("coupon" + couponval);
        console.log("coupontype" + coupontype);
        console.log("couponStatus" + couponStatus, typeof couponStatus);
        if (couponval !== "" && couponStatus !== "" && couponStatus !== -1) {
          setFormData((prev) => {
            return {
              ...prev,
              couponError: false,
              couponStatus: couponStatus,
            };
          });
          setShowApplyBtn(false);
          setOpenCoupon(false);

          let newPlans = [];
          plans.map((e) => {
            let discount = "",
              showActive = false,
              pair = {};
            if (
              coupontype === "discounted" &&
              plansApplicable.includes(`${e?.plan_id}`)
            ) {
              discount = Math.round(
                Number(e?.plan_price) -
                  (Number(couponval) / 100) * Number(e?.plan_price)
              );
              if (discount >= 0) {
                pair = { discountedPrice: discount, showActive: true };
              }
            } else if (
              coupontype === "flat" &&
              plansApplicable.includes(`${e?.plan_id}`)
            ) {
              discount = Math.round(Number(e?.plan_price) - Number(couponval));
              if (discount >= 0) {
                pair = { discountedPrice: discount, showActive: true };
              }
            } else if (coupontype === "free" && couponStatus === 2) {
              // Halfyearly hiddine plan
            } else if (
              coupontype === "free" &&
              plansApplicable.includes(`${e?.plan_id}`)
            ) {
              pair = {
                discountedPrice: Number(couponval).toFixed(),
                is_recommended: true,
                showActive: true,
              };
              // set freeCouponData
              setFreeCouponData(res?.data);
              // discount = Number(couponval).toFixed();
            } else {
              pair = { showActive };
            }
          
            e = { ...e, ...pair };
            newPlans.push(e);
            pair = {};
            discount = "";
            showActive = false;
          });
          // console.log(plans);
          setPlans(newPlans);
          if (coupontype === "free" && couponStatus === 2) {
            // hidden plan half yearly- assign direct subscription
            //Free Counpon for hidden plan
            let obj = {
              couponCode: res?.data?.coupon_code,
              status: res?.data?.status,
              ...res?.data?.coupon_details,
            };

            let { couponCode, plan_details_id } = obj;

            return (window.location.href = `${
              process.env.REACT_APP_HANGAMA_URL
            }/billing/pay.php?payment_id=13&plan_details_id=${plan_details_id}&type=charge&plan_id=${
              formData?.plan_id
            }&coupon_code=${couponCode}&${currenturl.replace("?", "")}`);
          }
        } else {
          setShowApplyBtn(false);
          setFormData((prev) => {
            return {
              ...prev,
              couponError: true,
              couponStatus: couponStatus,
            };
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  var bool = [];


  for (let df = 0; df < plans?.length; df++) {
    //free trial plan amt change

    const data = plans[df];
    if (df % 2) {
      continue;
    }
    let data1 = "";
    if (df + 1 !== "") {
      data1 = plans[df + 1];
    }
    // for (const data of plans) {
    bool.push({ data: data, data1: data1 });
  }

  useEffect(() => {
    //free trial
    if (freeTrialPlanData?.isActive === true) {
      checkFreeTrialStatus({ product_id, country, identity }).then((res) => {
        if (res?.status === SUCCESS) {
          if (
            res?.data?.free_trial_status === false &&
            freeTrialPlanData?.country === country &&
            freeTrialPlanData?.product_id === product_id
          ) {
            let obj = { ...freeTrialPlanData, status: false };
            setFreeTrialData(obj);
          } else {
            let obj = { ...freeTrialPlanData, status: true };
            setFreeTrialData(obj);
          }
        }
      });
    } else {
      let obj = { ...freeTrialPlanData, status: true };
      setFreeTrialData(obj);
    }
  }, [country, identity, product_id]);



  useEffect(() => {
    if (isLoading && Object.keys(freeTrialData)?.length > 0) {
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        "api-key": process.env.REACT_APP_API_KEY,
      };

      const body = {
        product_id: product_id,
        platform_id: platform_id,
        country: country,
        identity: identity,
      };
      // axios
      //     .get(`${process.env.REACT_APP_API_URL}/page/index.php?c=api&m=get_plan_option`)

      axios({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/v1/billing/plans?product_id=${body.product_id}&platform_id=${body.platform_id}&country=${body.country}&identity=${body.identity}`,
        headers: headers,
        // data: body,
      })
        .then((res) => {
          //Free Trial start
          if (freeTrialData?.status === false) {
            //free trial plan amt
            const rowToChange = res?.data?.plans?.findIndex((item) => {
              return item.plan_id === Number(freeTrialData?.plan_id);
            });
            res.data.plans[rowToChange].plan_price = freeTrialData?.plan_amt;
            res.data.plans[rowToChange].is_recommended = true;
            res.data.plans[rowToChange].freeTrialPlanRecText =
              freeTrialData?.freeTrialPlanRecText;
          }
          //Free Trial end
          const plansdata = res?.data?.plans;
          // const sliderInfodata = res.data.sliderInfo;
          let recommendedplan_id;
          plansdata.forEach((x, i) => {
            if (x.is_recommended) {
              recommendedplan_id = x.plan_id;
            }
          });

          // setFormData((prev) => {
          //   return {
          //     ...prev,
          //     plan_id: recommendedplan_id,
          //   };
          // });

          // setSlider(sliderInfodata);
          setPlans(plansdata);
          setOldPlans(plansdata);
          // console.log('coming')
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });

      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

    }
  }, [
    identity,
    npay_redirect,
    aff_id,
    campaignsource,
    npay_affilaite,
    npay_campaignsource,
    isLoading,
    product_id,
    platform_id,
    country,
    freeTrialData,
  ]);

   const closeConfirmationPopup =() =>{
    console.log('closeConfirmationPopup',formData?.plan_id)
    ampRabbitMQCustomEvent(
      {
        identity: identity,
        plan_id: formData?.plan_id,
        dismissal_method:"tap_outside"
      },
      "UPI_Drawer_Dismissed"
    );
    setShowConfirmationPopup(false);
  
  }

  const openConfirmation=(latest_plan_id)=>{
    ampRabbitMQCustomEvent(
      {
        identity: identity,
        plan_id: latest_plan_id,
      },
      "UPI_Drawer_Opened"
    );
    setShowConfirmationPopup(true);
  }

  useEffect(() => {
    if (openCoupon) {
      document.body.style.overflow = "hidden";
      document.body.style.position = "fixed"; //ios
      document.body.style.height = "100vh"; //ios
      document.body.style.width = "100%"; //ios
    } else if (!openCoupon) {
      document.body.style.overflow = "visible";
      document.body.style.removeProperty("position"); //ios
      document.body.style.removeProperty("height"); //ios
      document.body.style.removeProperty("width"); //ios
    }
  }, [openCoupon]);

  // useEffect(()=>{
  //   install('UA-217401863-1',{ 'send_page_view': false });
  // },[])
  // console.log("freeCouponData", freeCouponData);
  return (
    <>
      {isLoading ? (
        <div className="loading">Loading...</div>
      ) : (
        <>
          <div style={{ pointerEvents: openCoupon ? "none" : "auto" }}>
            <Swiper
              pagination={{ clickable: true }}
              className={"swiper1"}
              autoplay={{ delay: 3000 }}
            >
              {/* {sliders.length > 0 &&
                sliders.map((e, i) => (
                  <SwiperSlide key={i}>
                    <div className="swiper-slide">
                      <div className="slidiv">
                        <img src={e.img} alt="slider" />
                      </div>
                      {e.slider_type === "gold" ? (
                        <div className="goldicnslid"></div>
                      ) : (
                        <div className="noaddslid"></div>
                      )}
                      <div className="slidftxt">{e.slidftxt}</div>
                      <div className="slidstxt">{e.slidstxt}</div>
                    </div>
                  </SwiperSlide>
                ))} */}
              {/* static code for banner slide */}
              <SwiperSlide>
                <div className="swiper-slide">
                  <div className="slidiv">
                    <img
                      src={`https://images.hungama.com/c/uploads_hungama/20241129/20241129061141_mobile_banner_pay.png`}
                      alt="slider"
                    />
                  </div>
                  <div className="goldicnslid"></div>
                  <div className="slidftxt">{textData?.pay_banner_title_1}</div>
                  <div className="slidstxt">
                    {textData?.pay_banner_subtitle_1}
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="swiper-slide">
                  <div className="slidiv">
                    <img
                      src={
                        process.env.REACT_APP_ASSETS_URL +
                        `/mobile/images/movie_banner_mobile.png`
                      }
                      alt="slider"
                    />
                  </div>
                  <div className="noaddslid"></div>
                  <div className="slidftxt">{textData?.pay_banner_title_2}</div>
                  <div className="slidstxt">
                    {textData?.pay_banner_subtitle_2}
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
            <div className="plans" id="planstart">
              <div className="planmar">
                {/* <Swiper className="planSwiper" enabled={bool?.length===2?false:true}>{bool}</Swiper> */}
                <Swiper
                  className={`planSwiper`}
                  enabled={bool?.length === 2 ? false : true}
                >
                  {bool?.map((el, index) => {
                    return (
                      <SwiperSlide
                        className={`planSwiperSlider ${
                          bool?.length === 2 ? "plan-four-box" : ""
                        } `}
                        key={index}
                      >
                        <label
                          className={`container ${
                            el?.data?.showActive === true ? "active-plan" : ""
                          } ${
                            el?.data?.showActive === false
                              ? "in-active-plan"
                              : ""
                          }
                          `}
                        >
                          <div
                            className={`bxplan ${
                              el?.data?.plan_id === 37 ? "adsFree-lg-box" : ""
                            } 
                            `}
                          >
                            {el?.data.is_recommended && (
                              <>
                                <div
                                  className="rcomm"
                                  style={{ display: "none" }}
                                >
                                  {el?.data?.plan_id === 10
                                    ? "Loot Deal"
                                    : textData?.pay_recommended_text}
                                  {/* {textData?.pay_recommended_text} */}
                                </div>
                                <div>
                                  <img
                                    className="gifimg"
                                    alt="pay-gif"
                                    // src={
                                    //   process.env.REACT_APP_ASSETS_URL +
                                    //   "/mobile/images/holi_tag.svg"
                                    // }
                                    src={"https://images1.hungama.com/p/https://images.hungama.com/c/uploads_hungama/20240430/20240430071525_Summer_sale.png"}
                                  />
                                </div>
                              </>
                            )}
                            {/* Free Trial */}
                            {el?.data?.freeTrialPlanRecText && (
                              <div className="rcomm">
                                {/* {el?.data?.freeTrialPlanRecText} */}
                                {textData?.pay_trialoffer_text}
                              </div>
                            )}
                            {/* {data.is_recommended &&
                                                setFormData((prev) => {
                                                    return {
                                                        ...prev, plan_id: data.plan_id
                                                    }
                                                })
                                                // setFormData(data.plan_id)
                                            } */}
                            <div className="paddpan">
                              <p className="typeplan">
                                {langConvrt(
                                  el?.data.plan_name,
                                  defaultTextData,
                                  textData
                                )}
                              </p>
                              {el?.data.discountedPrice ? (
                                <div className="ruppetxt">
                                  <span>
                                    {el?.data.plan_currency_symbol}
                                    {el?.data.plan_price}
                                  </span>
                                  {el?.data.plan_currency_symbol}
                                  {el?.data.discountedPrice}
                                </div>
                              ) : //PLAN PRICE CHANGE EFFECT
                              el?.data?.original_price >
                                el?.data?.plan_price ? (
                                <div className="ruppetxt">
                                  <span>
                                    {el?.data?.plan_currency_symbol}
                                    {el?.data?.original_price}
                                  </span>
                                  {el?.data?.plan_currency_symbol}
                                  {el?.data?.plan_price}
                                </div>
                              ) : (
                                <div className="ruppetxt">
                                  {el?.data.plan_currency_symbol}
                                  {el?.data.plan_price}
                                </div>
                              )}
                            </div>
                            {/* <label className="container"> */}
                            <input
                              type="radio"
                              // defaultChecked={
                              //   plan_id == el?.data.plan_id
                              //     ? "defaultChecked"
                              //     : el?.data.is_recommended && "defaultChecked"
                              // }
                              value={
                                el?.data.plan_name +
                                ":" +
                                el?.data.plan_id +
                                ":" +
                                el?.data.plan_price
                              }
                              name="plan_id"
                              checked={formData.plan_id === el?.data?.plan_id} 
                              onChange={(e) => handleForm(e, el?.data)}
                            />
                            <span className="checkmark"></span>
                          </div>
                        </label>
                        {el?.data1 && (
                          <label
                            className={`container ${
                              el?.data1?.showActive === true
                                ? "active-plan"
                                : ""
                            }
                            ${
                              el?.data1?.showActive === false
                                ? "in-active-plan"
                                : ""
                            }
                            `}
                          >
                            <div className={`bxplan`}>
                              {el?.data1.is_recommended && (
                                <>
                                  <div
                                    className="rcomm"
                                    style={{ display: "none" }}
                                  >
                                    {el?.data1?.plan_id === 10
                                      ? "Loot Deal"
                                      : textData?.pay_recommended_text}
                                    {/* {textData?.pay_recommended_text} */}
                                  </div>
                                  <div>
                                    <img
                                      className="gifimg"
                                      alt="pay-gif"
                                      // src={
                                      //   process.env.REACT_APP_ASSETS_URL +
                                      //   "/mobile/images/holi_tag.svg"
                                      // }
                                      src={"https://images1.hungama.com/p/https://images.hungama.com/c/uploads_hungama/20240430/20240430071525_Summer_sale.png"}
                                    />
                                  </div>
                                </>
                              )}
                              {/* Free Trial */}
                              {el?.data1?.freeTrialPlanRecText && (
                                <div className="rcomm">
                                  {/* {el?.data1?.freeTrialPlanRecText} */}
                                  {textData?.pay_trialoffer_text}
                                </div>
                              )}
                              {/* {data1.is_recommended && setFormData(data.planid = data1.plan_id)} */}

                              <div className="paddpan">
                                <p className="typeplan">
                                  {langConvrt(
                                    el?.data1.plan_name,
                                    defaultTextData,
                                    textData
                                  )}
                                </p>
                                {el?.data1.discountedPrice ? (
                                  <div className="ruppetxt">
                                    <span>
                                      {el?.data1.plan_currency_symbol}
                                      {el?.data1.plan_price}
                                    </span>
                                    {el?.data1.plan_currency_symbol}
                                    {el?.data1.discountedPrice}
                                  </div>
                                ) : el?.data1?.original_price >
                                  el?.data1?.plan_price ? (
                                  <div className="ruppetxt">
                                    <span>
                                      {el?.data1.plan_currency_symbol}
                                      {el?.data1.original_price}
                                    </span>
                                    {el?.data1.plan_currency_symbol}
                                    {el?.data1.plan_price}
                                  </div>
                                ) : (
                                  <div className="ruppetxt">
                                    {el?.data1.plan_currency_symbol}
                                    {el?.data1.plan_price}
                                  </div>
                                )}
                              </div>
                              {/* <label className="container"> */}
                              <input
                                type="radio"
                                // defaultChecked={
                                //   plan_id == el?.data1.plan_id
                                //     ? "defaultChecked"
                                //     : el?.data1.is_recommended &&
                                //       "defaultChecked"
                                // }
                                value={
                                  el?.data1.plan_name +
                                  ":" +
                                  el?.data1.plan_id +
                                  ":" +
                                  el?.data1.plan_price
                                }
                                name="plan_id"
                                checked={formData.plan_id === el?.data?.plan_id} 
                                onChange={(e) => handleForm(e, el?.data1)}
                              />
                              <span className="checkmark"></span>
                            </div>
                          </label>
                        )}
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </div>
              {country?.toLowerCase() === "in" && (
                <div className="cpbxcon">
                  <div className="cpbxcon-input-box">
                    <input
                      autoComplete="off"
                      className={
                        formData.couponError === true
                          ? "error-border"
                          : (formData?.couponError === false &&
                              formData?.couponStatus === 1) ||
                            (formData?.couponError === false &&
                              formData?.couponStatus === 2)
                          ? "success-border"
                          : ""
                      }
                      type="text"
                      name="couponcode"
                      id="couponcode"
                      placeholder={textData?.pay_coupon_placeholder}
                      value={formData.couponcode}
                      onChange={(e) => handleForm(e)}
                    />

                    <a
                      href="!#"
                      style={{
                        backgroundColor: applyBtn === true ? "white" : "",
                        pointerEvents: applyBtn === true ? "auto" : "none",
                        display: showApplyBtn === false ? "none" : "",
                      }}
                      className="button-blue"
                      onClick={(e) => verifyCoupon(e)}
                    >
                      {textData?.pay_coupon_apply_text}
                    </a>

                    {!showApplyBtn && (
                      <img
                        alt="close icon"
                        onClick={(e) => handleClearCoupon(e, identity)}
                        src={
                          process.env.REACT_APP_ASSETS_URL +
                          "/mobile/images/coupon-error-close-new.svg"
                        }
                        className="error-closebtn"
                      />
                    )}
                  </div>
                  {formData?.couponError && formData?.couponStatus === 0 && (
                    <div className="error-box">
                      {textData?.pay_coupon_invalid_text}
                    </div>
                  )}
                  {formData?.couponError && formData?.couponStatus === -1 && (
                    <div className="error-box">
                      {textData?.pay_coupon_expired_text}
                    </div>
                  )}
                  {!formData?.couponError && formData?.couponStatus === 1 && (
                    <div className="success-box">
                      {textData?.pay_coupon_valid_text}
                    </div>
                  )}
                  {!formData?.couponError && formData?.couponStatus === 2 && (
                    <div className="success-box">
                      {textData?.pay_coupon_valid_text}
                    </div>
                  )}
                </div>
              )}
              <div className="goldbgmain">
                <input type="hidden" id="x_plan_name" />
                <input type="hidden" id="x_plan_price" />

                <div onClick={(e) => buygold()} className="goldbtn pointer">
                  <img
                    src={
                      process.env.REACT_APP_ASSETS_URL +
                      "/mobile/images/goldimg.svg"
                    }
                    alt="gold"
                  />{" "}
                  {textData?.pay_buyplan_btn_text}
                </div>
                <div className="CTR">
                <p className="alsub" style={{borderBottom:"0px  solid"}}>
        <span style={{color: 'white',fontSize: '0.7rem'}}>Cancel Anytime</span>
      </p>
      </div>
              </div>
            </div>

            <div className="mainwrp">
              <div className="header">
                <div className="arwback" onClick={goToPreviousPath}>
                  <span className="icon-Back" onClick={goToPreviousPath}></span>
                </div>
                <div className="hlogo"></div>
              </div>
            </div>

{/* {payments.length > 0 && (
  <div
    onClick={() => closeConfirmationPopup()} // Close on outside click
    style={{
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: showConfirmationPopup ? "rgba(0, 0, 0, 0.5)" : "transparent", // Dim background
      display: showConfirmationPopup ? "block" : "none",
      zIndex: 999, // Behind popup
    }}
  >
    <aside
      data-pushbar-id="bottom2"
      style={{
        position: "fixed",
        bottom: showConfirmationPopup ? "0" : "-100%", // Slide up/down effect
        left: "0",
        width: "100%",
        padding: "20px",
        background: "currentColor",
        borderRadius: "10px 10px 0 0",
        boxShadow: "0px -5px 15px rgba(0, 0, 0, 0.3)",
        zIndex: 1000,
        transition: "bottom 0.3s ease-in-out",
      }}
      onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside
    >
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();
          setShowConfirmationPopup(false);
          planPage();
        }}
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          color: "#9191d1",
          cursor: "pointer",
          fontFamily: "'Arial', sans-serif",
          fontSize: "18px",
        }}
      >
        More Options
      </a>
      {payments
        .sort((a, b) => parseFloat(a.display_order) - parseFloat(b.display_order))
        .map((data, i) => (
          <div key={i}>
            {data.plan_group_type === "upiPayments" && (
              <Upipayments
              showConfirmationPopup={showConfirmationPopup}
                tabHideShow={tabHideShow}
                setTabHideShow={setTabHideShow}
                pushbar={pushbar}
                setPushbar={setPushbar}
                plan_id={plan_id}
                aff_id={aff_id}
                utm_source={utm_source}
                planData={plan}
                uL={newUpiList}
                identity={identity}
                product_id={product_id}
                platform_id={platform_id}
                upiPayments={upiPayments}
                urlparams={currenturl}
                textData={textData}
                coupon_code={isCouponValid ? formData.couponcode : ""}
                appVersion={appVersion}
                buildNumber={buildNumber}
                isFreeTrial={freeTrialData}
                jusPayUser={jusPayIdentity}
              />
            )}
          </div>
        ))}
    </aside>
  </div>
)} */}

{payments.length > 0 && (
  <div
    onClick={() => closeConfirmationPopup()} // Close on outside click
    style={{
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: showConfirmationPopup ? "rgba(0, 0, 0, 0.5)" : "transparent", // Dim background
      display: showConfirmationPopup ? "block" : "none",
      zIndex: 999, // Behind popup
    }}
  >
    <aside
      data-pushbar-id="bottom2"
      style={{
        position: "fixed",
        bottom: showConfirmationPopup ? "0" : "-100%", // Slide up/down effect
        left: "0",
        width: "100%",
        padding: "20px",
        background: "currentColor",
        borderRadius: "10px 10px 0 0",
        boxShadow: "0px -5px 15px rgba(0, 0, 0, 0.3)",
        zIndex: 1000,
        transition: "bottom 0.3s ease-in-out",
      }}
      onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside
    >
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();
          setShowConfirmationPopup(false);
          planPage();
        }}
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          color: "#9191d1",
          cursor: "pointer",
          fontFamily: "'Arial', sans-serif",
          fontSize: "18px",
        }}
      >
        More Options
      </a>
      {payments
        .sort((a, b) => parseFloat(a.display_order) - parseFloat(b.display_order))
        .map((data, i) => (
          <div key={i}>
            {data.plan_group_type === "upiPayments" && (
              <>
                <Upipayments
                  showConfirmationPopup={showConfirmationPopup}
                  tabHideShow={tabHideShow}
                  setTabHideShow={setTabHideShow}
                  pushbar={pushbar}
                  setPushbar={setPushbar}
                  plan_id={plan_id}
                  aff_id={aff_id}
                  utm_source={utm_source}
                  planData={plan}
                  uL={newUpiList}
                  identity={identity}
                  product_id={product_id}
                  platform_id={platform_id}
                  upiPayments={upiPayments}
                  urlparams={currenturl}
                  textData={textData}
                  coupon_code={isCouponValid ? formData.couponcode : ""}
                  appVersion={appVersion}
                  buildNumber={buildNumber}
                  isFreeTrial={freeTrialData}
                  jusPayUser={jusPayIdentity}
                  state={state}
                />



              </>
            )}
          </div>
        ))}
    </aside>
  </div>
)}

              {/* popup end */}
          </div>
        </>
      )}
    </>
  );
}
