import React from "react";
import { Helmet } from "react-helmet";
 
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { BrowserView, MobileView } from "react-device-detect";
 
import { Plan } from "./components/mobile/pages/plan";
import { RentPlan } from "./components/mobile/pages/rentplan";
import { Payment } from "./components/mobile/pages/payment";
import { NonAppPayment } from "./components/mobile/pages/nonapppayment";
import { Netbanking } from "./components/mobile/pages/netbanking";
import { MobilePaymentFailed } from "./components/mobile/pages/mobilepaymentfailed";
import { VidacomPaymentFailed } from "./components/mobile/pages//vodacompaymentfailed";
import { Paymentsuccess } from "./components/mobile/pages/paymentsuccess";
import { Vodacomsuccess } from "./components/mobile/pages/vodacomsuccess";
/* pleng imports start */
import { PlengPlan } from "./components/mobile/pleng_pay/pages/plan";
import { PlengPayment } from "./components/mobile/pleng_pay/pages/payment";
import { LinkAccount } from "./components/mobile/pleng_pay/pages/link_account";
import { PlengMobilePaymentFailed } from "./components/mobile/pleng_pay/pages/mobilepaymentfailed";
import { PlengPaymentSuccess } from "./components/mobile/pleng_pay/pages/paymentsuccess";
/* pleng imports end */
 
import { PlengPaymentCRBT } from "./components/mobile/pleng_pay/pages/crbt/paymentCRBT";
import { PlengMobilePaymentFailedCRBT } from "./components/mobile/pleng_pay/pages/crbt/mobilepaymentfailedCRBT";
import { PlengPaymentSuccessCRBT } from "./components/mobile/pleng_pay/pages/crbt/paymentsuccessCRBT";
 
import { DesktopPlan } from "./components/desktop/pages/plan";
import { DesktopPayment } from "./components/desktop/pages/payment";
import { DesktopPaymentfailed } from "./components/desktop/pages/fail";
import { DesktopVodacomfailed } from "./components/desktop/pages/vodacomfail";
import { DesktopPaymentsuccess } from "./components/desktop/pages/sucess";
import { VodacomDesktopsuccess } from "./components/desktop/pages/vodacom_sucess";
import { TamashaPaymentsuccess } from "./components/desktop/pages/tamasha_sucess";
import { Tamashamobilesuccess } from "./components/mobile/pages/tamashamobilesuccess";
import { TamashaPlan } from "./components/desktop/pages/tamasha_plan";
 import { MobileTamashaPlan } from "./components/mobile/pages/mobiletamashaplan";
import { DesktopTamashafailed } from "./components/desktop/pages/tamshafail";
import { TamashaPaymentFailed } from "./components/mobile/pages/mobiletamashafailed";
 
import { VodacomPlan } from "./components/desktop/pages/vodacomplan";
import { MobileVodacomPlan } from "./components/mobile/pages/vodacomplan";
 
import Notfound from "./components/desktop/pages/notfound";
import { Retry } from "./components/desktop/pages/retry";
import { Order } from "./components/desktop/pages/order";
 
export default function Hungama() {
  const Css = ({ path, page }) => {
    return (
      <>
        {path === "mobile" && (
          <Helmet>
            <link
              rel="stylesheet"
              href={
                "https://assets-pay.hungama.com/assets/un/" +
                path +
                "/css/iconmoon.css?v=1.3"
              }
            />
            <link
              rel="stylesheet"
              href={
                "https://assets-pay.hungama.com/assets/un/" +
                path +
                "/css/style.css?v=1.0.44"
              }
            />
            <link
              rel="stylesheet"
              href={
                "https://assets-pay.hungama.com/assets/un/" +
                path +
                "/css/mpaystyle.css"
              }
            />
            <link
              rel="stylesheet"
              href={
                "https://assets-pay.hungama.com/assets/un/" +
                path +
                "/css/swiper-bundle.css?v=1.1"
              }
            />
            <link
              rel="stylesheet"
              href={
                "https://assets-pay.hungama.com/assets/un/" +
                path +
                "/css/button-style.css?v=1.0.3"
              }
            />
            <link
              rel="stylesheet"
              href={
                "https://assets-pay.hungama.com/assets/un/" +
                path +
                "/css/custom.css?v=1.0.8"
              }
            />
          </Helmet>
        )}
 
        {path === "non-app-pay-mobile" && page === "non-app-payment" && (
          <Helmet>
            <link
              rel="stylesheet"
              href={
                "https://assets-pay.hungama.com/assets/un/mobile/non-app-pay-camp/css/style.css?v=1.1"
              }
            />
            <link
              rel="stylesheet"
              href={
                "https://assets-pay.hungama.com/assets/un/mobile/non-app-pay-camp/css/swiper-bundle.css?v=1.1"
              }
            />
          </Helmet>
        )}
 
        {/* pleng mobile */}
        {path === "pleng-mobile" && (
          <Helmet>
            <link
              rel="stylesheet"
              href={
                "https://assets-pay.hungama.com/assets/un/mobile/pleng/css/iconmoon.css?v=1.0"
              }
            />
            <link
              rel="stylesheet"
              href={
                "https://assets-pay.hungama.com/assets/un/mobile/pleng/css/style.css?v=1.0.14"
              }
            />
            <link
              rel="stylesheet"
              href={
                "https://assets-pay.hungama.com/assets/un/mobile/pleng/css/swiper-bundle.css?v=1.0.1"
              }
            />
            <link
              rel="stylesheet"
              href={
                "https://assets-pay.hungama.com/assets/un/mobile/pleng/css/button-style.css?v=1.0.0"
              }
            />
            <script
              src={
                "https://assets-pay.hungama.com/assets/un/mobile/pleng/js/swiper-bundle.min.js"
              }
            ></script>
            <script
              src={
                "https://assets-pay.hungama.com/assets/un/mobile/pleng/js/custom.js"
              }
            ></script>
            {/* <script type="text/javascript">
                           new Pushbar({blur:false,overlay:true});
                            </script> */}
          </Helmet>
        )}
 
        {/* pleng mobile */}
 
        {path === "web" && page === "payment" && (
          <Helmet>
            <link
              rel="stylesheet"
              href={
                "https://assets-pay.hungama.com/assets/un/" +
                path +
                "/css/iconmoon.css?v=1.0.3"
              }
            />
                                    <link
              rel="stylesheet"
              href={
                "https://assets-pay.hungama.com/assets/un/" +
                path +
                "/css/mpaystyle.css"
              }
            />
            <link
              rel="stylesheet"
              href={
                "https://assets-pay.hungama.com/assets/un/" +
                path +
                "/css/style.css?v=2.0.19"
              }
            />
 
            <link
              rel="stylesheet"
              href={
                "https://assets-pay.hungama.com/assets/un/" +
                path +
                "/css/custom.css?v=1.0.4"
              }
            />
          </Helmet>
        )}
 
        {path === "web" && (page === "fail" || page === "success") && (
          <Helmet>
            <link
              rel="stylesheet"
              href={
                "https://assets-pay.hungama.com/assets/un/" +
                path +
                "/css/iconmoon.css?v=1.0.3"
              }
            />
                                                <link
              rel="stylesheet"
              href={
                "https://assets-pay.hungama.com/assets/un/" +
                path +
                "/css/mpaystyle.css"
              }
            />
            <link
              rel="stylesheet"
              href={
                "https://assets-pay.hungama.com/assets/un/" +
                path +
                "/css/style.css?v=2.0.19"
              }
            />
            <link
              rel="stylesheet"
              href={
                "https://assets-pay.hungama.com/assets/un/" +
                path +
                "/css/global.css?v=1.0.4"
              }
            />
            <link
              rel="stylesheet"
              href={
                "https://assets-pay.hungama.com/assets/un/" +
                path +
                "/css/responsive.css?v=1.0.4"
              }
            />
            <link
              rel="stylesheet"
              href={
                "https://assets-pay.hungama.com/assets/un/" +
                path +
                "/css/button-style.css?v=1.0.2"
              }
            />
            <link
              rel="stylesheet"
              href={
                "https://assets-pay.hungama.com/assets/un/" +
                path +
                "/css/success_fail.css?v=1.0.6"
              }
            />
          </Helmet>
        )}
 
        {path === "web" && page === "plan" && (
          <Helmet>
            <link
              rel="stylesheet"
              href={
                "https://assets-pay.hungama.com/assets/un/" +
                path +
                "/css/iconmoon.css?v=1.0.3"
              }
            />
                                                <link
              rel="stylesheet"
              href={
                "https://assets-pay.hungama.com/assets/un/" +
                path +
                "/css/mpaystyle.css"
              }
            />
            <link
              rel="stylesheet"
              href={
                "https://assets-pay.hungama.com/assets/un/" +
                path +
                "/css/style.css?v=2.0.19"
              }
            />
            <link
              rel="stylesheet"
              href={
                "https://assets-pay.hungama.com/assets/un/" +
                path +
                "/css/global.css?v=1.0.3"
              }
            />
            <link
              rel="stylesheet"
              href={
                "https://assets-pay.hungama.com/assets/un/" +
                path +
                "/css/responsive.css?v=1.0.4"
              }
            />
            <link
              rel="stylesheet"
              href={
                "https://assets-pay.hungama.com/assets/un/" +
                path +
                "/css/swiper-bundle.css?v=1.2"
              }
            />
            <link
              rel="stylesheet"
              href={
                "https://assets-pay.hungama.com/assets/un/" +
                path +
                "/css/custom.css?v=1.0.4"
              }
            />
          </Helmet>
        )}
      </>
    );
  };
 
  // const screenWidth = window.innerWidth;
  // console.log(screenWidth);
 
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/plan">
          <BrowserView>
            <Css path={"web"} page="plan" />
            <DesktopPlan path />
          </BrowserView>
          <MobileView>
            <Css path={"mobile"} />
            <Plan />
          </MobileView>
        </Route>
        <Route path="/rent">
          <MobileView>
            <Css path={"mobile"} />
            <RentPlan />
          </MobileView>
        </Route>
        {/* pleng plan start  */}
        <Route path="/pleng/plan">
          <MobileView>
            <Css path={"pleng-mobile"} />
            <PlengPlan />
          </MobileView>
        </Route>
        {/* pleng plan end  */}
        <Route path="/payment">
          <BrowserView>
            <Css path={"web"} page="payment" />
            <DesktopPayment path />
          </BrowserView>
          <MobileView>
            <Css path={"mobile"} />
            <Payment />
          </MobileView>
        </Route>
        <Route path="/nonapp_payment">
          <MobileView>
            <Css path={"non-app-pay-mobile"} page={"non-app-payment"} />
            <NonAppPayment />
          </MobileView>
        </Route>
        {/* pleng payment start */}
        <Route path="/pleng/payment">
          <MobileView>
            <Css path={"pleng-mobile"} />
            <PlengPayment />
          </MobileView>
        </Route>
        {/* pleng payment end */}
 {/* LinkAccount start */}
 <Route path="/pleng/link_account">
          <MobileView>
            <Css path={"pleng-mobile"} />
            <LinkAccount />
          </MobileView>
        </Route>
        {/* LinkAccount end */}
        <Route path="/tamasha/plan">
          <BrowserView>
            <Css path={"web"} page="plan" />
            <TamashaPlan path />
          </BrowserView>
          <MobileView>
            <Css path={"mobile"} />
            <MobileTamashaPlan />
          </MobileView>
        </Route>
 
 
        <Route path="/vodacom/plan">
          <BrowserView>
            <Css path={"web"} page="plan" />
            <VodacomPlan path />
          </BrowserView>
          <MobileView>
            <Css path={"mobile"} />
            <MobileVodacomPlan />
          </MobileView>
        </Route>
 
        <Route path="/payment_fail">
          <BrowserView>
            <Css path={"web"} page={"fail"} />
            <DesktopPaymentfailed />
          </BrowserView>
          <MobileView>
            <Css path={"mobile"} />
            <MobilePaymentFailed />
          </MobileView>
        </Route>
 
        <Route path="/vodacom_fail">
          <BrowserView>
            <Css path={"web"} page={"fail"} />
            <DesktopVodacomfailed />
          </BrowserView>
          <MobileView>
            <Css path={"mobile"} />
            <VidacomPaymentFailed />
          </MobileView>
        </Route>
 
        <Route path="/tamasha_fail">
          <BrowserView>
            <Css path={"web"} page={"fail"} />
            <DesktopTamashafailed />
          </BrowserView>
          <MobileView>
            <Css path={"mobile"} />
            <TamashaPaymentFailed />
          </MobileView>
        </Route>
 
        <Route path="/pleng/payment_crbt">
          <MobileView>
            <Css path={"pleng-mobile"} />
            <PlengPaymentCRBT />
          </MobileView>
        </Route>
        <Route path="/pleng/payment_crbt_fail">
          <MobileView>
            <Css path={"pleng-mobile"} />
            <PlengMobilePaymentFailedCRBT />
          </MobileView>
        </Route>
        <Route path="/pleng/payment_crbt_success">
          <MobileView>
            <Css path={"pleng-mobile"} />
            <PlengPaymentSuccessCRBT />
          </MobileView>
        </Route>
        {/* pleng payment_fail start */}
        <Route path="/pleng/payment_fail">
          <MobileView>
            <Css path={"pleng-mobile"} />
            <PlengMobilePaymentFailed />
          </MobileView>
        </Route>
        {/* pleng payment_fail end */}
        <Route path="/payment_success">
          <BrowserView>
            <Css path={"web"} page={"success"} />
            <DesktopPaymentsuccess />
          </BrowserView>
          <MobileView>
            <Css path={"mobile"} />
            <Paymentsuccess />
          </MobileView>
        </Route>
        <Route path="/vodacom_success">
          <BrowserView>
            <Css path={"web"} page={"success"} />
            <VodacomDesktopsuccess />
          </BrowserView>
          <MobileView>
            <Css path={"mobile"} />
            <Vodacomsuccess />
          </MobileView>
        </Route>
        <Route path="/tamasha_success">
          <BrowserView>
            <Css path={"web"} page={"success"} />
            <TamashaPaymentsuccess />
          </BrowserView>
          <MobileView>
            <Css path={"mobile"} />
            <Tamashamobilesuccess />
          </MobileView>
        </Route>
        {/* pleng payment_success start */}
        <Route path="/pleng/payment_success">
          <MobileView>
            <Css path={"pleng-mobile"} />
            <PlengPaymentSuccess />
          </MobileView>
        </Route>
        {/* pleng payment_success end */}
        <Route path="/404">
          <BrowserView>
            <Css path={"web"} page={"fail"} />
            <Notfound />
          </BrowserView>
          <MobileView>
            <div>Not Found</div>
          </MobileView>
        </Route>
        {/* pleng page notfound start */}
        <Route path="/pleng/404">
          <MobileView>
            <div>Not Found</div>
          </MobileView>
        </Route>
        {/* pleng page notfound end */}
        <Route
          path="/retry/:order_id/:lang?"
          render={(props) => (
            <Retry
              order_id={props.match.params.order_id}
              lang={
                props?.match?.params?.lang ? props?.match?.params?.lang : "en"
              }
            />
          )}
        />
        {/* pleng retry page start */}
        <Route
          path="/pleng/retry/:order_id/:lang?"
          render={(props) => (
            <Retry
              order_id={props.match.params.order_id}
              lang={
                props?.match?.params?.lang ? props?.match?.params?.lang : "en"
              }
            />
          )}
        />
        {/* pleng retry page end */}
        <Route
          path="/order/:order_id/:lang?"
          render={(props) => (
            <Order
              order_id={props.match.params.order_id}
              lang={
                props?.match?.params?.lang ? props?.match?.params?.lang : "en"
              }
            />
          )}
        />
        {/* pleng order page start */}
        <Route
          path="/pleng/order/:order_id/:lang?"
          render={(props) => (
            <Order
              order_id={props.match.params.order_id}
              lang={
                props?.match?.params?.lang ? props?.match?.params?.lang : "en"
              }
            />
          )}
        />
        {/* pleng order page end */}
        {/* <Route path="/netbanking/:lang">
          <Css path={"mobile"} />
          <Netbanking lang={props.match.params.lang} />
        </Route> */}
        <Route
          path="/netbanking/:lang?"
          render={(props) => (
            <>
              <Css path={"mobile"} />
              <Netbanking
                lang={
                  props?.match?.params?.lang ? props?.match?.params?.lang : "en"
                }
              />
            </>
          )}
        />
 
        {/* New Test route for pay success and fail page HM */}
        <Route path="/payment-fail">
          <BrowserView>
            <Css path={"web"} page={"fail"} />
            <DesktopPaymentfailed />
          </BrowserView>
          <MobileView>
            <Css path={"mobile"} />
            <MobilePaymentFailed />
          </MobileView>
        </Route>
        <Route path="/payment-success">
          <BrowserView>
            <Css path={"web"} page={"success"} />
            <DesktopPaymentsuccess />
          </BrowserView>
          <MobileView>
            <Css path={"mobile"} />
            <Paymentsuccess />
          </MobileView>
        </Route>
      </Switch>
    </BrowserRouter>
  );
}